import axios from 'app/utils/AxiosConfig'
import Constants from 'app/utils/Constants'
import React, { useState, useEffect, useMemo } from 'react'

import { Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Store from 'app/utils/Store'
import CommonDialog from "app/components/dialog/CommonDialog";
import CommonForm from "app/components/form/CommonForm";
import CommonTable from "app/components/table/CommonTable";
import clsx from 'clsx';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import BoardList from './BoardList'
import './Leads.css'
import GetAppIcon from '@material-ui/icons/GetApp';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import { useDispatch } from "react-redux";
import moment from 'moment-timezone'
import CreateCardWithStep from './CreateCardWithStep'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfirmDialog from "app/components/dialog/ConfirmDialog";
import { Tooltip } from '@material-ui/core';
import WinCard from './winCard'
import FunnelPanel from './FunnelPanel'
import EditLocationIcon from '@material-ui/icons/EditLocation';
import AddIcon from '@material-ui/icons/Add';
import { eventBus } from "app/utils/EventListener";
import { useLocation } from "react-router";
import Visualization from './SingleLead/Visualization';
import ListProposalLeads from './Proposals/ListProposalLeads';
import TimelineIcon from '@material-ui/icons/Timeline';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { makeStyles } from '@material-ui/core/styles';

let logged_user = Store.USER
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        width: 520,
        padding: 15
    },
}));
export default function NewLeads({ visuplac }) {
    const { state } = useLocation();
    const dispatch = useDispatch();
    const [kanban, setKanban] = useState([])
    const [openCreateColumn, setOpenCreateColumn] = useState(false)
    const [usersOptions, setUsersOptions] = useState([])
    const [clients, setClients] = useState([])
    const [agencies, setAgencies] = useState([])
    const [dataTable, setDataTable] = useState([])
    const [tags, setTags] = useState([])
    const [page, setPage] = useState('kanban')

    const [selectedColumn, setSelectedColumn] = useState({})
    const [openCreateActivity, setOpenCreateActvity] = useState(false)
    const [idCard, setIdCard] = useState(0)
    const [activitySelected, setActivitySelected] = useState({})
    const [deleteDialogActivity, setDeleteDialogActivity] = useState(false)
    const [openCreateNewCardWithStep, setOpenCreateNewCardWithStep] = useState(false)

    const [mailings, setMailings] = useState([])
    const [vehicles, setVehicles] = useState([])
    const [products, setProducts] = useState([])
    const [users, setUsers] = useState([])
    const [squares, setSquares] = useState([])
    const [concludedDialog, setConcludedDialog] = useState(false)
    const [openWinCard, setOpenWinCard] = useState(false)
    const [openLossCard, setOpenLossCard] = useState(false)
    const [openFunnelPanel, setOpenFunnelPanel] = useState(false)
    const [funnels, setFunnels] = useState([])
    const [idFunnelSelected, setIdFunnelSelected] = useState(0)
    const [deleteFunnelDialog, setDeleteFunnelDialog] = useState(false)
    const [showTimeLine, setShowTimeLine] = useState(false)
    const [filters, setFilters] = useState({ status: 'progress' })
    const [buttonSelected, setButtonSelected] = useState(0)
    const [openLead, setOpenLead] = useState(false)
    const [selectedLead, setSelectedLead] = useState([])
    const [selectedLine, setSelectedLine] = useState(undefined)
    const [columnsKanbam, setColumnsKanbam] = useState([])
    const [openFiltersSidebar, setOpenFiltersSidebar] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const classes = useStyles();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    useEffect(() => {
        getFunnels()
        axios.post(
            Constants.APIEndpoints.PROPOSAL + "/getallgeneraldata", {
            entities: ['products', 'agencies', 'users', 'clients', 'vehicles', 'squares', 'mailings', 'tags']
        }).then((res) => {
            let users_ = res.data.users.map(u => ({ value: u.id_user, label: u.name }))
            setAgencies(res.data.agencies)
            setUsersOptions(users_)
            setUsers(res.data.users)
            setClients(res.data.clients)
            setVehicles(res.data.vehicles)
            setMailings(res.data.mailings)
            setSquares(res.data.squares)
            setProducts(res.data.products)
            setTags(res.data.tags);
        })

        getColumns(localStorage.getItem('fk_id_salles_funnel') ?? idFunnelSelected)
        openFromActivityHistory()
    }, [])

    const getClients = (all = false) => {
        axios.post(Constants.APIEndpoints.CLIENT + `/getAllClients?`, { all }).then((res) => {
            setClients(res.data);
        });
    }
    const getAgencies = (all = false) => {
        axios.post(Constants.APIEndpoints.AGENCY + `/getallagencies?`, { all }).then((res) => {
            setAgencies(res.data);
        });
    }

    useEffect(() => {
        if (page == 'table') {
            getDataTable()
        }
    }, [page])

    useEffect(() => {
        if (page == 'table') {
            getDataTable(filters)
        }
    }, [filters])

    useEffect(() => {
        eventBus.on('openLead', (data) => {
            openFromActivityHistory(data)
        })
    }, [])
    const openFromActivityHistory = (newState) => {
        if (!newState) {
            newState = state
        }
        if (newState) {
            if (newState.mode == 'view') {
                getSingleLead(newState.fk_id_card)
                setOpenLead(true)
            } else {
                axios.get(Constants.APIEndpoints.KANBAN + '/getstatuscard/' + newState.fk_id_card).then(res => {
                    if (res.data.win) {
                        toast.error("Lead já deu ganho, não pode ser editado");
                    } else if (res.data.loss) {
                        toast.error("Lead já deu perda, não pode ser editado");
                    } else {
                        getSingleLead(newState.fk_id_card)
                        setOpenCreateNewCardWithStep(true)
                    }
                })
            }
        }
    }

    const getDataTable = (values = {}) => {
        axios.post(Constants.APIEndpoints.LEADS + "/getallleads", { fk_id_salles_funnel: idFunnelSelected, ...values }).then(res => {
            setSelectedLine(undefined)
            setDataTable(res.data)
        })
    }

    const getData = (values = {}) => {

    }

    const getColumns = (fk_id_salles_funnel = 0) => {
        if (fk_id_salles_funnel > -1) {
            setIdFunnelSelected(fk_id_salles_funnel)
            localStorage.setItem('fk_id_salles_funnel', fk_id_salles_funnel)
        } else {
            const id_salles_funnel = localStorage.getItem('fk_id_salles_funnel')
            if (!isNaN(id_salles_funnel)) {
                setIdFunnelSelected(id_salles_funnel)
                fk_id_salles_funnel = id_salles_funnel
            } else {
                fk_id_salles_funnel = 0
            }
        }
        setShowTimeLine(false)
        setPage('kanban')

        axios.get(Constants.APIEndpoints.LEADS + '/getcolumns/' + fk_id_salles_funnel).then(res => {
            setColumnsKanbam(res.data)
        })
    }

    const getFunnels = () => {
        setPage('kanban')
        axios.get(Constants.APIEndpoints.KANBAN + '/getfunnels').then(res => {
            const funnelDefault = { id_salles_funnel: 0, name: 'Padrão', user_cad: 0 }
            setFunnels([funnelDefault, ...res.data])
        })
    }

    const exportCards = () => {
        let file = `Cards_${moment().format('DDMMYYYY_HH:mm:ss')}.xlsx`
        const token = sessionStorage.getItem('token');
        fetch(Constants.APIEndpoints.LEADS + '/exportleads',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Access-Token': token

                },
                body: JSON.stringify({ fk_id_salles_funnel: idFunnelSelected })
            }).then(results => {
                return results.blob()
            }).then(response => {
                let data = response;
                let elm = document.createElement('a');
                elm.href = URL.createObjectURL(data);
                elm.setAttribute('download', file);
                elm.click();
                elm.remove();
            })
    }

    const onDragEnd = (result) => {
        try {
            const notification = toast("Movendo");
            const { destination, source, draggableId } = result
            const columnOrigin = source.droppableId.replace('column', '')
            const columnDestination = destination.droppableId.replace('column', '')
            const lead = JSON.parse(draggableId)
            if (result.type == 'card') {
                eventBus.emit('lead-moved', { columnDestination, columnOrigin, lead })
                const data = {
                    fk_id_column_destination: columnDestination,
                    fk_id_leads: lead.id_leads
                }
                axios.post(Constants.APIEndpoints.LEADS + "/movelead", data).then(res => {
                    eventBus.emit('update-column', {})
                    toast.update(notification, { render: "Movido", type: toast.TYPE.SUCCESS, isLoading: false });
                })

            } else {
                const data = {
                    origin: source.index,
                    destination: destination.index
                }
                axios.post(Constants.APIEndpoints.KANBAN + "/moveColumn", data).then(res => {
                    getData({ fk_id_salles_funnel: idFunnelSelected })
                    toast.update(notification, { render: "Movido", type: toast.TYPE.SUCCESS, isLoading: false });

                })
            }
        } catch (e) {
            console.log('erro', e)

        }
    }

    const lossCard = (values) => {
        values.id_leads = selectedLead.id_leads
        values.loss = true
        axios.post(Constants.APIEndpoints.LEADS + '/confirmwinlead', values).then(res => {
            setOpenLossCard(false)
            setConcludedDialog(false)
            eventBus.emit('won-modal-card', {})
            eventBus.emit('update-column', {})
        })
    }

    const createNewKanban = (values) => {
        values.user_cad = logged_user.id_user
        values.fk_id_salles_funnel = idFunnelSelected
        axios.post(Constants.APIEndpoints.KANBAN + "/createColumn", values).then(res => {
            getData({ fk_id_salles_funnel: idFunnelSelected });
            setOpenCreateColumn(false)
        })
    }

    const columns = [
        {
            id: "opensub",
            visible: true,
            width: 28,
            noSearchable: true,
            Cell: ({ row }) => (
                <Tooltip title={<h5>Visualizar Propostas</h5>} placement="right">
                    <IconButton
                        onClick={() => {
                            if (selectedLine == row.id) {
                                setSelectedLine(undefined)
                            } else {
                                setSelectedLead(row.original)
                                setSelectedLine(row.id)
                            }
                        }}
                    >
                        <Icon>{selectedLine !== row.id ? 'arrow_downward' : 'arrow_upward'}</Icon>
                    </IconButton >
                </Tooltip>
            )
        },

        {
            Header: 'Etiquetas',
            accessor: 'tags',
            noSearchable: true,
            Cell: ({ row }) => (
                <div>
                    {(row.original.tags || []).map(tag => (
                        <div style={{
                            backgroundColor: tag.color, height: 20, margin: 4, borderRadius: 10, display: 'flex', color: 'white', fontSize: 10,
                            justifyContent: 'center', alignItems: 'center'
                        }}>{tag.name}</div>
                    ))}
                </div>
            )
        },
        {
            Header: "Campanha",
            accessor: "campaign",
            className: "font-medium",
            sortable: true,
        },
        {
            Header: "Valor",
            accessor: "value",
            className: "font-medium",
            sortable: true,
            Cell: ({ row }) => (
                <div>
                    {parseFloat((row.original.value || 0)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                </div>
            )
        },
        {
            Header: "Início",
            accessor: "dt_start",
            className: "font-medium",
            sortable: true,
            Cell: ({ row }) => (
                <div>
                    {row.original.dt_start ? moment(row.original.dt_start).format('DD/MM/YYYY') : ''}
                </div>
            )
        }, {
            Header: "Término",
            accessor: "dt_end",
            className: "font-medium",
            sortable: true,
            Cell: ({ row }) => (
                <div>
                    {row.original.dt_end ? moment(row.original.dt_end).format('DD/MM/YYYY') : ''}
                </div>
            )
        },
        {
            Header: "Data de Cadastro",
            accessor: "dt_cad",
            className: "font-medium",
            sortable: true,
            Cell: ({ row }) => (
                <div>
                    {row.original.dt_cad ? moment(row.original.dt_cad).format('DD/MM/YYYY') : ''}
                </div>
            )
        },
        {
            Header: "Data de Edição",
            accessor: "dt_up",
            className: "font-medium",
            sortable: true,
            Cell: ({ row }) => (
                <div>
                    {row.original.dt_up ? moment(row.original.dt_up).format('DD/MM/YYYY') : ''}
                </div>
            )
        },
        {
            Header: "Cliente",
            accessor: "client",
            className: "font-medium",
            sortable: true,
        },
        {
            Header: "Agência",
            accessor: "agency",
            sortable: true,
        },
        {
            Header: "Coluna",
            accessor: "column_name",
            sortable: true,
        },
    ]

    const saveCardAcvitivy = (values) => {
        values.user_cad = logged_user.id_user
        values.fk_id_card = idCard

        if (moment(`${values.dt_start} ${values.time_start}`).isSameOrAfter(moment(`${values.dt_end} ${values.time_end}`))) {
            toast("Data de término não pode ser inferior a de início");
        } else {
            const url = values.id_card_activity ? '/updateactivity' : '/createactivity'
            axios.post(Constants.APIEndpoints.KANBAN + url, values).then(res => {
                setOpenCreateActvity(false)
                setConcludedDialog(false)
            })
        }
    }

    const deleteActivity = () => {
        axios.delete(Constants.APIEndpoints.KANBAN + '/deleteactivity/' + activitySelected.id_card_activity).then(res => {
            setDeleteDialogActivity(false)
        })
    }
    const createNewCard = (values) => {
        values.user_cad = logged_user.id_user
        values.id_column = selectedColumn.id_kanban_column
        const url = values.id_cards ? '/updateCard' : '/createCard'
        axios.post(Constants.APIEndpoints.KANBAN + url, values).then(res => {
            setSelectedLead([])
            setIdCard(res.data.insertId)
            eventBus.emit('update-column', {})
        })
    }

    const deleteFunnel = () => {
        axios.post(Constants.APIEndpoints.KANBAN + '/deletefunnel', { id_salles_funnel: idFunnelSelected }).then(res => {
            getData({ fk_id_salles_funnel: 0 })
            setDeleteFunnelDialog(false)
            getFunnels()
        })
    }

    const getTimeLine = () => {
        setShowTimeLine(true)
        axios.post(Constants.APIEndpoints.LEADS + "/gettimeline", filters).then(res => {
            setPage('kanban')
            setColumnsKanbam(res.data)
        })
    }

    const getSingleLead = (id_leads, openCreateLead) => {
        axios.get(Constants.APIEndpoints.LEADS + "/getsinglelead/" + id_leads).then(res => {
            setSelectedLead(res.data[0])
            if (openCreateLead) {
                setOpenCreateNewCardWithStep(true)
            }
        })
    }


    const subTable = (
        <ListProposalLeads
            users={users}
            isSubTable={true}
            vehicles={vehicles}
            lead={selectedLead}
            agencies={agencies}

        />
    )
    return (
        <div style={{ height: '100%' }}>
            <ToastContainer
                position="bottom-right"
                autoClose={4500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover={false}
            />
            <div
                style={{ width: '100%', display: 'flex', justifyContent: 'space-between', height: 80 }}>

                {logged_user.role == 'admin' && showTimeLine == false && (
                    <button className='buttonCreateNewColumn' onClick={() => setOpenCreateColumn(true)}>
                        <AddIcon />
                    </button>
                )}


                <SwipeableDrawer
                    classes={{ paper: clsx(classes.root) }}
                    open={openFiltersSidebar}
                    anchor="right"
                    onOpen={(ev) => { }}
                    onClose={() => setOpenFiltersSidebar(false)}
                    disableSwipeToOpen
                >
                    <CommonForm
                        fields={[
                            {
                                label: 'Etiquetas',
                                col: 12,
                                name: 'tags',
                                type: 'multiselect',
                                options: tags.map(k => ({ value: k.id_tags, label: k.name, color: k.color })),
                            },
                            {
                                col: 12,
                                name: 'fk_id_user',
                                label: 'Executivo',
                                type: 'select',
                                isClearable: true,
                                options: users.filter(u => u.fk_id_role == 'executive').map(u => ({ value: u.id_user, label: u.name }))
                            },
                            {
                                col: 12,
                                name: 'fk_id_vehicle',
                                label: 'Veículo',
                                type: 'select',
                                isClearable: true,
                                options: vehicles.map(vehicle => ({ value: vehicle.id_vehicle, label: vehicle.fancy_name }))
                            },
                            {
                                col: 12,
                                name: 'typePeriod',
                                label: 'Período',
                                type: 'select',
                                options: [{ value: 1, label: 'Hoje' }, { value: 2, label: 'Esta semana' }, { value: 3, label: 'Este mês' }, { value: 4, label: 'Este ano' }, { value: 5, label: 'Personalizado' }]
                            },
                            {
                                col: 12,
                                name: 'follow',
                                type: 'select',
                                label: 'Follow',
                                options: [{ value: 1, label: 'Todos' }, { value: 2, label: 'Não' }, { value: 3, label: 'Sim' }]
                            },
                            {
                                col: 6,
                                type: 'date',
                                name: 'dt_start',
                                label: 'De',
                                disabled: filters.typePeriod !== 5
                            },
                            {
                                col: 6,
                                type: 'date',
                                name: 'dt_end',
                                label: 'Até',
                                disabled: filters.typePeriod !== 5
                            },
                        ]}
                        values={filters}
                        onChangeField={(f, v) => {
                            let values_ = filters
                            values_[f.name] = v
                            setFilters({ ...values_ })
                        }}
                    />
                </SwipeableDrawer>

                <div style={{ width: '90%', marginTop: -10 }}>
                    <CommonForm
                        fields={[
                            {
                                label: 'Campanha',
                                col: 3,
                                name: 'campaign',
                                type: 'text',
                            },
                            {
                                label: 'PI',
                                col: 3,
                                name: 'number',
                                type: 'text',
                            },
                            {
                                col: 3,
                                name: 'fk_id_client',
                                label: 'Cliente',
                                type: 'select',
                                isClearable: true,
                                options: clients.map(client => ({ value: client.id_client, label: client.fancy_name }))
                            },
                            {
                                col: 3,
                                name: 'status',
                                label: 'Status',
                                type: 'select',
                                options: [
                                    { value: 'finished', label: 'Finalizados' },
                                    { value: 'progress', label: 'Em Andamento' },
                                    { value: 'not_realized', label: 'Não Realizadas' },
                                    { value: 'archived', label: 'Arquivados' },
                                    { value: 'all', label: 'Todos' }]
                            },
                        ]}
                        values={filters}
                        onChangeField={(f, v) => {
                            let values_ = filters
                            values_[f.name] = v
                            setFilters({ ...values_ })
                        }}
                    />
                </div>
                <div style={{ display: 'flex' }}>
                    <IconButton
                        onClick={() => setOpenFiltersSidebar(true)}
                    >
                        <Icon>
                            settings
                        </Icon>
                    </IconButton>


                    {showTimeLine && (
                        <IconButton
                            onClick={() => getTimeLine()}>
                            <Icon>
                                search
                            </Icon>
                        </IconButton>
                    )}
                    <IconButton onClick={handleClick}
                    >
                        <MoreVertIcon
                            style={{
                                fontSize: 25
                            }} />
                    </IconButton>
                </div>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem
                        onClick={() => {
                            setButtonSelected(1);
                            setOpenFunnelPanel(true);
                            handleClose();
                        }}
                        selected={buttonSelected === 1}
                    >
                        <Tooltip title={<h2>Funis de venda</h2>} placement="right">
                            <EditLocationIcon />
                        </Tooltip>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            setButtonSelected(2);
                            setPage(page === 'kanban' ? 'table' : 'kanban');
                            handleClose();
                        }}
                        selected={buttonSelected === 2}
                    >
                        <Tooltip title={<h2>Visualizar como Tabela</h2>} placement="right">
                            <AutorenewIcon />
                        </Tooltip>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            setButtonSelected(3);
                            exportCards();
                            handleClose();
                        }}
                        selected={buttonSelected === 3}
                    >
                        <Tooltip title={<h2>Exportar Leads</h2>} placement="right">
                            <GetAppIcon />
                        </Tooltip>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            setButtonSelected(4);
                            getTimeLine();
                            handleClose();
                        }}
                        selected={buttonSelected === 4}
                    >
                        <Tooltip title={<h2>Linha do Tempo</h2>} placement="right">
                            <TimelineIcon />
                        </Tooltip>
                    </MenuItem>
                </Menu>
            </div>


            <CommonDialog
                open={openLossCard}
                onClose={() => setOpenLossCard(false)}
                title="Perda"
                width="md"
                fullWidth={true}
            >
                <CommonForm
                    fields={[
                        {
                            col: 12,
                            name: 'loss',
                            label: 'Justificativa',
                            type: 'textarea',
                        },
                    ]}
                    onSubmit={lossCard}
                />

            </CommonDialog>

            <CommonDialog
                open={openCreateNewCardWithStep}
                onClose={() => setOpenCreateNewCardWithStep(false)}
                title="Criar Novo Card"
                width="lg"
                keep={true}
            >
                <CreateCardWithStep
                    onSubmit={createNewCard}
                    clients={clients}
                    getClients={getClients}
                    getAgencies={getAgencies}
                    agencies={agencies}
                    mailings={mailings}
                    kanban={kanban}
                    lead={selectedLead}
                    setSelectedLead={setSelectedLead}
                    getSingleLead={getSingleLead}
                    tags={tags}
                    users={users}
                    getData={() => getData({ fk_id_salles_funnel: idFunnelSelected })}
                    values={selectedLead}
                    selectedColumn={selectedColumn}
                    setOpenCreateNewCardWithStep={setOpenCreateNewCardWithStep}
                    setDeleteDialogActivity={setDeleteDialogActivity}
                    vehicles={vehicles}
                    products={products}
                    squares={squares}
                    selectedLead={selectedLead}
                />
            </CommonDialog>

            {
                deleteDialogActivity ? (
                    <ConfirmDialog title="Deseja deletar esta atividade" cancel={() => setDeleteDialogActivity(false)} confirm={deleteActivity} />
                ) : null
            }
            {
                deleteFunnelDialog ? (
                    <ConfirmDialog title="Deseja deletar este Funil" cancel={() => setDeleteFunnelDialog(false)} confirm={deleteFunnel} />
                ) : null
            }
            {
                concludedDialog ? (
                    <ConfirmDialog title="Qual a Próxima Etapa?"
                        width='sm'
                        options={[
                            { title: 'Dar Ganho', color: 'green', onClick: () => setOpenWinCard(true) },
                            { title: 'Dar Perda', color: 'red', onClick: () => setOpenLossCard(true) },
                            {
                                title: 'Criar Nova Atividade', onClick: () => {
                                    setActivitySelected({ concludedActivity: activitySelected.id_card_activity })
                                    setOpenCreateActvity(true)
                                }
                            },

                        ]}
                    />
                ) : null
            }
            {
                openFunnelPanel ? (
                    <FunnelPanel
                        onClose={() => setOpenFunnelPanel(false)}
                        open={openFunnelPanel}
                        users={users}
                        funnels={funnels}
                        getFunnels={getFunnels}
                        idFunnelSelected={idFunnelSelected}
                        getColumns={getColumns}
                        setDeleteFunnelDialog={setDeleteFunnelDialog}
                        setIdFunnelSelected={setIdFunnelSelected}
                    />
                ) : null
            }
            <WinCard
                open={openWinCard}
                onClose={() => {
                    setOpenWinCard(false)
                    setConcludedDialog(false)
                }
                }
                card={selectedLead}
                getData={() => getData({ fk_id_salles_funnel: idFunnelSelected })}
                id_card_activity={activitySelected.id_card_activity}
            />
            <CommonDialog
                open={openCreateActivity}
                onClose={() => setOpenCreateActvity(false)}
                title='Nova Atividade'
                width='lg'
                fullWidth={true}
            >
                <CommonForm
                    fields={[
                        {
                            col: 5,
                            type: 'text',
                            name: 'name',
                            label: 'Nome'
                        },
                        {
                            col: 3,
                            type: 'select',
                            name: 'fk_id_user',
                            label: 'Responsável',
                            options: users.map(u => ({ value: u.id_user, label: u.name }))
                        },

                        {
                            col: 4,
                            type: 'checkbox',
                            name: 'sendmail',
                            label: 'Receber email lembrando dessa atividade?'
                        },
                        {
                            col: 6,
                            type: 'date',
                            name: 'dt_start',
                            label: 'Data de Início'
                        },
                        {
                            col: 6,
                            type: 'time',
                            name: 'time_start',
                            label: 'Hora de Início'
                        },
                        {
                            col: 6,
                            type: 'date',
                            name: 'dt_end',
                            label: 'Data de Término'
                        },
                        {
                            col: 6,
                            type: 'time',
                            name: 'time_end',
                            label: 'Hora de Término'
                        }, {
                            col: 12,
                            type: 'textarea',
                            name: 'description',
                            label: 'Descrição'
                        }
                    ]}
                    onSubmit={saveCardAcvitivy}
                    values={activitySelected}
                    onChangeField={(f, v) => {
                        let v_ = { ...activitySelected }
                        v_[f.name] = v;
                        setActivitySelected(v_)
                    }}
                />
            </CommonDialog>
            <CommonDialog
                open={openCreateColumn}
                onClose={() => setOpenCreateColumn(false)}
                title="Criar Nova Coluna"
                width="xs"
            >
                <CommonForm
                    fields={[
                        {
                            col: 12,
                            name: 'name',
                            label: 'Nome',
                            type: 'text',
                            required: true
                        }
                    ]}
                    onSubmit={createNewKanban}

                />
            </CommonDialog>
            <CommonDialog
                open={openLead}
                onClose={() => setOpenLead(false)}
                title="Visualizar Negócio"
                width="xl"
                fullScreen={true}
            >
                <Visualization
                    visuplac={visuplac}
                    lead={selectedLead}
                    users={users}
                    vehicles={vehicles}
                    getData={getData}
                    setOpenWinCard={setOpenWinCard}
                    setOpenLossCard={setOpenLossCard}
                    usersOptions={usersOptions}
                    getSingleLead={getSingleLead}
                    agencies={agencies}

                />
            </CommonDialog>
            {
                page == 'kanban' ? (
                    <div style={{ height: '79vh', padding: 10, backgroundColor: 'white', margin: '0px -10px' }} className={clsx('flex flex-1 overflow-x-auto overflow-y-hidden')}>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="list" type="list" direction="horizontal">
                                {(provided) => (
                                    <div ref={provided.innerRef} className="flex ">
                                        {columnsKanbam.map((column, index) => (
                                            <BoardList
                                                key={column.id_kanban_column}
                                                column={column}
                                                showTimeLine={showTimeLine}
                                                index={index}
                                                setOpenCreateNewCardWithStep={setOpenCreateNewCardWithStep}
                                                setSelectedColumn={setSelectedColumn}
                                                setSelectedLead={setSelectedLead}
                                                selectedLead={selectedLead}
                                                getSingleLead={getSingleLead}
                                                setOpenLead={setOpenLead}
                                                filters={filters}
                                                idFunnelSelected={idFunnelSelected}
                                            />
                                        ))}
                                        {provided.placeholder}

                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                ) : (
                    <div style={{ height: '79vh', padding: 10, backgroundColor: 'white', margin: '0px -10px' }} className={clsx('flex flex-1 overflow-x-auto overflow-y-hidden')}>

                        <CommonTable
                            miniVersion={true}
                            columns={columns}
                            subTable={subTable}
                            selectedLine={selectedLine}
                            data={dataTable}
                            noHeader={true}
                            id={'leads_table_id'}
                            onRowClick={(ev, row) => {
                                if (row) {
                                    // handleClickRow(row.original)
                                }
                            }}
                        />
                    </div>
                )
            }
        </div >
    )
}