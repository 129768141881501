import React, { useState, useEffect } from 'react';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Typography, Divider } from '@material-ui/core';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import moment from 'moment-timezone'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Constants from "app/utils/Constants";
import axios from "app/utils/AxiosConfig";
import Store from 'app/utils/Store'
import CommonForm from 'app/components/form/CommonForm';
import { eventBus } from "app/utils/EventListener";

let logged_user = Store.USER
const access = logged_user && logged_user.access ? logged_user.access : {}
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'white',
        width: 720,
        padding: 15
    },
}));


function CreateProposalLead
    ({
        open,
        onClose,
        users,
        proposalSelected,
        setProposalSelected,
        uuidData,
        isEditMode,
        getProposals,
        lead,
        vehicles,
        agencies

    }) {
    const classes = useStyles();

    const saveProposal = (values) => {
        const data = {
            fk_id_leads: lead?.id_leads,
            uuid: uuidData,
            ...proposalSelected
        }
        const url = values.id_leads_proposals ? '/updateproposallead' : '/saveproposallead'
        axios.post(Constants.APIEndpoints.LEADS + url, data).then(res => {
            getProposals()
            onClose()
            eventBus.emit('update-column', {})
            
        })
    }

    const getSingleVehicle = (id) => {
        axios
            .get(
                Constants.APIEndpoints.VEHICLE + "/getAllVehicles?id=" + id)
            .then((res) => {
                let values_ = proposalSelected
                values_.sponsor_vehicle = res.data[0].sponsor || ''
                values_.fk_id_vehicle = id
                setProposalSelected({ ...values_ })
            })
    }
    const deleteProposal = (proposal) => {
        axios.post(Constants.APIEndpoints.DASHBOARD + '/deletedash', proposal).then(res => {

        })
    }

    if (Object.keys(proposalSelected).length > 0) {
        proposalSelected.dt_start = proposalSelected.dt_start ? moment(proposalSelected.dt_start).format('YYYY-MM-DD') : ''
        proposalSelected.dt_end = proposalSelected.dt_end ? moment(proposalSelected.dt_end).format('YYYY-MM-DD') : ''
    }

    if (Object.keys(proposalSelected).length == 0 && lead) {
        proposalSelected.campaign = lead.campaign
        proposalSelected.fk_id_agency = lead.fk_id_agency
        proposalSelected.dt_start = lead.dt_start ? moment(lead.dt_start).format('YYYY-MM-DD') : ''
        proposalSelected.dt_end = lead.dt_end ? moment(lead.dt_end).format('YYYY-MM-DD') : ''
    }

    return (

        <SwipeableDrawer
            classes={{ paper: clsx(classes.root) }}
            open={open}
            anchor="right"
            onOpen={(ev) => { }}
            onClose={onClose}
            disableSwipeToOpen

        >
            <div className="flex flex-col space-y-32">
                <CommonForm
                    values={proposalSelected}
                    onChangeField={(f, v) => {
                        const newProposal = { ...proposalSelected }
                        newProposal[f.name] = v
                        if (f.name == 'fk_id_vehicle') {
                            getSingleVehicle(v)
                        }
                        if (f.name == 'gross_value_negotiated') {
                            newProposal.net_value_negotiated = v - (v * 0.2)
                        }
                        if (f.name == 'gross_value_approved') {
                            newProposal.net_value_approved = v - (v * 0.2)
                        }
                        if (f.name == 'gross_value_predicted') {
                            newProposal.net_value_predicted = v - (v * 0.2)
                        }
                        setProposalSelected(newProposal)

                    }}
                    fields={[
                        {
                            col: 12,
                            name: 'number',
                            label: 'PI',
                            type: 'text',
                        },
                        {
                            col: 12,
                            type: 'text',
                            name: 'campaign',
                            label: 'Campanha'
                        },
                        {
                            col: 12,
                            name: 'fk_id_responsable',
                            label: 'Executivo',
                            type: 'select',
                            options: users.filter(u => u.fk_id_role == 'executive').map(user => ({ value: user.id_user, label: user.name })),
                            required: true
                        },
                        {
                            col: 12,
                            name: 'fk_id_vehicle',
                            label: 'Veículo',
                            type: 'select',
                            options: vehicles.map(vehicle => ({ value: vehicle.id_vehicle, label: vehicle.fancy_name }))
                        },
                        {
                            col: 12,
                            name: 'product',
                            label: 'Produto',
                            type: 'text'
                        },
                        {
                            col: 12,
                            name: 'fk_id_agency',
                            label: 'Agência',
                            type: 'select',
                            options: agencies.map(agency => ({ value: agency.id_agency, label: agency.fancy_name }))
                        },
                        {
                            col: 12,
                            name: 'sponsor_vehicle',
                            label: 'Representante',
                            type: 'text'
                        },
                        {
                            col: 6,
                            name: 'dt_start',
                            label: 'Data de Início',
                            type: 'date',
                        },
                        {
                            col: 6,
                            name: 'dt_end',
                            label: 'Data de Término',
                            type: 'date',
                        },
                        {
                            col: 12,
                            name: 'gross_value_negotiated',
                            label: 'Valor Bruto Negociado',
                            type: 'money'
                        },
                        {
                            col: 12,
                            name: 'net_value_negotiated',
                            label: 'Valor Líquido Negociado',
                            type: 'money'
                        },
                        {
                            col: 12,
                            name: 'gross_value_predicted',
                            label: 'Valor Bruto Previsto',
                            type: 'money'
                        },
                        {
                            col: 12,
                            name: 'net_value_predicted',
                            label: 'Valor Líquido Previsto',
                            type: 'money'
                        },
                        {
                            col: 12,
                            name: 'gross_value_approved',
                            label: 'Valor Bruto Aprovado',
                            type: 'money'
                        },
                        {
                            col: 12,
                            name: 'net_value_approved',
                            label: 'Valor Líquido Aprovado',
                            type: 'money'
                        },
                        {
                            col: 12,
                            name: 'observation',
                            type: 'textarea',
                            label: 'Observação'
                        }

                    ]}
                    onSubmit={saveProposal}
                />


            </div>

        </SwipeableDrawer >
    );
}

export default CreateProposalLead
    ;
