import React, { useEffect, useState } from 'react'
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import moment from 'moment'
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import { ToastContainer, toast } from 'react-toastify';
import CheckIcon from '@mui/icons-material/Check';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CommonForm from "app/components/form/CommonForm"
import CommonTable from "app/components/table/CommonTable";
import CommonDialog from "app/components/dialog/CommonDialog";
import axios from 'app/utils/AxiosConfig'
import Constants from 'app/utils/Constants'
import Store from 'app/utils/Store'
import ConfirmDialog from "app/components/dialog/ConfirmDialog";
import { Divider } from '@material-ui/core';
import ListProposalLeads from './Proposals/ListProposalLeads';
import { v4 as uuidv4 } from 'uuid';
import { eventBus } from "app/utils/EventListener";
import ListClientsLeads from './Lists/ListClientsLeads';
import ListAgenciesLeads from './Lists/ListAgenciesLeads';

const logged_user = Store.USER

const CreateCardWithStep = ({
    onSubmit,
    clients,
    getClients,
    agencies,
    getAgencies,
    mailings,
    kanban,
    users,
    selectedLead,
    setSelectedLead,
    getSingleLead,
    tags,
    values,
    setOpenCreateNewCardWithStep,
    selectedColumn,
    getData,
    vehicles,
    visuplac,
    products,
    squares
}) => {
    const [openCreateActivity, setOpenCreateActvity] = useState(false)
    const [activitySelected, setActivitySelected] = useState([])
    const [cardActivities, setCardActivities] = useState([])
    const [deleteDialogActivity, setDeleteDialogActivity] = useState(false)
    const [openNewMailing, setOpenNewMailing] = useState(false)
    const [newMailing, setNewMailing] = useState([])
    const [mailingName, setMailingName] = useState(values.contact_name || '')
    const [mailingId, setMailingId] = useState(values.contact || '')
    const [filesToRemove, setFilesToRemove] = useState([])
    const [files, setFiles] = useState([])
    const [uuidData, setUuidData] = useState()
    const [openClientsList, setOpenClientsList] = useState(false)
    const [openAgenciesList, setOpenAgenciesList] = useState(false)

    useEffect(() => {
        setUuidData(uuidv4())
    }, [])
    useEffect(() => {
        if (values?.files) {
            setFiles(values.files)
        }
    }, [values])

    const removeFile = (field, file) => {
        let filesRemove = filesToRemove
        filesRemove.push(file)
        setFilesToRemove(filesRemove)
        const newFiles = files.filter(v => v.filepath !== file.filepath)
        setFiles(newFiles)
        const newLead = selectedLead
        newLead.files = newFiles
        setSelectedLead(newLead)
    }

    const getCardActivities = (card = 0) => {
        const id = card == 0 ? values.id_cards : card
        axios.get(Constants.APIEndpoints.KANBAN + '/getcardactivities/' + id).then(res => {
            const activities = res.data.map(act => ({
                ...act,
                time_start: act.dt_start ? moment(act.dt_start).format('HH:mm') : '',
                dt_start: act.dt_start ? moment(act.dt_start).format('YYYY-MM-DD') : '',
                time_end: act.dt_end ? moment(act.dt_end).format('HH:mm') : '',
                sendmail: act.send_mail ? 'Sim' : 'Não',
                dt_end: act.dt_end ? moment(act.dt_end).format('YYYY-MM-DD') : ''
            }))
            setCardActivities(activities)
        })
    }

    const saveCardAcvitivy = (values) => {
        let cards = cardActivities
        if (!values.id_card_activity && !values.prev_id) {
            values.prev_id = cardActivities.length
            cards.push(values)
        }
        if (values.id_card_activity) {
            cards.filter((p) => p.id_card_activity == values.id_card_activity)
                .map((p) => {
                    Object.keys(p).map(key => {
                        p[key] = values[key]
                    })
                });
        }
        if (values.prev_id) {
            cards.filter((p) => p.prev_id == values.prev_id)
                .map((p) => {
                    Object.keys(p).map(key => {
                        p[key] = values[key]
                    })
                });
        }
        setCardActivities(cards)
        setOpenCreateActvity(false)
    }

    const deleteActivity = () => {
        axios.delete(Constants.APIEndpoints.KANBAN + '/deleteactivity/' + activitySelected.id_card_activity).then(res => {
            setDeleteDialogActivity(false)
            getCardActivities()
        })
    }


    const saveLead = () => {
        let valuesLead = selectedLead
        valuesLead.id_column = selectedColumn.id_kanban_column
        valuesLead.uuid = selectedLead.id_leads ? undefined : uuidData
        const url = valuesLead.id_leads ? '/updatelead' : '/savelead'
        axios.post(Constants.APIEndpoints.LEADS + url, valuesLead).then(() => {
            setSelectedLead([])
            setOpenCreateNewCardWithStep(false)
            setOpenCreateActvity(false)
            getClients()
            eventBus.emit('update-column', {})
        })
    }

    const createNewMailing = (name) => {
        setOpenNewMailing(true)
        let mailing = newMailing
        mailing.name = name
        setNewMailing(mailing)
    }

    const saveMailing = (values) => {
        axios.post(Constants.APIEndpoints.MAILING + '/createMailing', values).then(res => {
            setOpenNewMailing(false)
            setMailingName(values.name)
            let v_ = { ...selectedLead }
            v_.contact = res.data.insertId;
            getSingleLead(v_.id_cards)
            setMailingId(res.data.insertId)
        })
    }

    const showAllClients = () => {
        setOpenClientsList(true)
    }
    const showAllAgencies = () => {
        setOpenAgenciesList(true)
    }

    const selectClient = (id_client, updateClients) => {
        if (updateClients) {
            getClients(true)
        }
        setSelectedLead(prev => ({ ...prev, fk_id_client: id_client }))
        setOpenClientsList(false)
    }

    const selectAgency = (id_agency, updateAgencies) => {
        if (updateAgencies) {
            getAgencies(true)
        }
        setSelectedLead(prev => ({ ...prev, fk_id_agency: id_agency }))
        setOpenAgenciesList(false)
    }

    if (selectedLead) {
        selectedLead.dt_start = selectedLead.dt_start ? moment(selectedLead.dt_start).format('YYYY-MM-DD') : ''
        selectedLead.dt_end = selectedLead.dt_end ? moment(selectedLead.dt_end).format('YYYY-MM-DD') : ''
    }

    return (
        <>
            <ToastContainer
                position="bottom-right"
                autoClose={4500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover={false}
            />
            {deleteDialogActivity ? (
                <ConfirmDialog title="Deseja deletar esta atividade" cancel={() => setDeleteDialogActivity(false)} confirm={deleteActivity} />
            ) : null}
            {openClientsList && (
                <ListClientsLeads
                    open={openClientsList}
                    onClose={() => setOpenClientsList(false)}
                    selectItem={selectClient}
                    clients={clients}
                    agencies={agencies}
                />
            )}
            {openAgenciesList && (
                <ListAgenciesLeads
                    open={openAgenciesList}
                    onClose={() => setOpenAgenciesList(false)}
                    selectItem={selectAgency}
                    clients={clients}
                    agencies={agencies}
                />
            )}
            <div>
                <CommonForm
                    fields={[
                        {
                            col: 12,
                            type: 'content',
                            content: (
                                <>
                                    <h2>Lead</h2>
                                    <Divider />
                                </>
                            )
                        },
                        {
                            col: 12,
                            name: 'campaign',
                            label: 'Campanha',
                            type: 'text',
                        },
                        {
                            col: 12,
                            name: 'tags_',
                            label: 'Etiquetas',
                            type: 'multiselect',
                            options: tags.map(k => ({ value: k.id_tags, label: k.name, color: k.color })),
                        },
                        {
                            col: 12,
                            name: 'fk_id_client',
                            label: 'Cliente',
                            type: 'select',
                            options: clients.map(c => ({ value: c.id_client, label: c.fancy_name })),
                            required: true,
                            showAll: showAllClients
                        },
                        {
                            col: 12,
                            name: 'fk_id_agency',
                            label: 'Agência',
                            type: 'select',
                            options: agencies.map(a => ({ value: a.id_agency, label: a.fancy_name })),
                            showAll: showAllAgencies
                        }, {
                            col: 6,
                            name: 'dt_start',
                            label: 'Data de início',
                            type: 'date',
                        }, {
                            col: 6,
                            name: 'dt_end',
                            label: 'Data de Fechamento',
                            type: 'date',
                        },
                        {
                            col: 12,
                            name: 'value',
                            label: 'Verba da Campanha',
                            type: 'money'
                        },
                        {
                            col: 12,
                            type: 'content',
                            content: (
                                <>
                                    <h2>Briefing</h2>
                                    <Divider />
                                </>
                            )
                        },
                        {
                            col: 12,
                            name: 'target',
                            label: 'Objetivo',
                            type: 'text',
                        },
                        {
                            col: 12,
                            name: 'squares',
                            label: 'Praças',
                            type: 'multiselect',
                            options: squares.map(a => ({ value: a.id_square, label: a.uf })),
                        },
                        {
                            col: 12,
                            type: "multiselect",
                            name: "middles",
                            label: "Meios",
                            options: [
                                { value: 1, label: "TV" },
                                { value: 2, label: "INTERNET" },
                                { value: 3, label: "RADIO" },
                                { value: 4, label: "OOH" },
                                { value: 5, label: "DOOH" },
                                { value: 6, label: "JORNAL" },
                                { value: 7, label: "REVISTA" },
                                { value: 8, label: "PORTAIS" },
                                { value: 9, label: "REDES SOCIAIS" },
                                { value: 10, label: "VERTICAIS" },
                                { value: 11, label: "WIFI" },
                                { value: 12, label: "REDE DE APPS" },
                                { value: 13, label: "REDE DE CONTEUDO" },
                                { value: 14, label: "REDE DE VIDEO" },
                                { value: 15, label: "MOBILIDADE URBANA" },
                                { value: 16, label: "TV ABERTA" },
                                { value: 17, label: "PAY TV" },
                                { value: 18, label: "CTV" },
                            ],
                        },
                        {
                            col: 12,
                            name: 'observation',
                            label: 'Detalhamento da campanha',
                            type: 'textarea',
                        },
                        {
                            col: 12,
                            type: 'content',
                            content: (
                                <>
                                    <h2>Propostas</h2>
                                    <Divider />
                                </>
                            )
                        },
                        {
                            col: 12,
                            type: 'content',
                            content: (
                                <ListProposalLeads
                                    uuidData={uuidData}
                                    users={users}
                                    vehicles={vehicles}
                                    lead={selectedLead}
                                    agencies={agencies}
                                />

                            )
                        },

                    ]}
                    values={{
                        ...selectedLead,
                        files: files,
                    }}
                    onChangeField={(f, v) => {
                        let v_ = { ...selectedLead }
                        v_[f.name] = v;
                        setSelectedLead(v_)
                    }}
                    removeFile={removeFile}
                    onSubmit={saveLead}
                />

            </div>
        </>
    )
}

export default CreateCardWithStep