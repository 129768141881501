import React, { useState, useEffect } from "react"
import CreateProposalLead from "./CreateProposalLead"
import CommonTable from "app/components/table/CommonTable"
import moment from "moment"
import { Icon, IconButton, Tooltip, Typography } from '@material-ui/core'
import axios from 'app/utils/AxiosConfig'
import Constants from 'app/utils/Constants'
import CommonDialog from "app/components/dialog/CommonDialog"
import CommonForm from "app/components/form/CommonForm"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { eventBus } from "app/utils/EventListener";
import ConfirmDialog from "app/components/dialog/ConfirmDialog"
import '../Leads.css'
import Store from "app/utils/Store"
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
const user = Store.USER


const List = ({ lead, uuidData, vehicles, agencies, users, viewMode, getSingleLead, isSubTable }) => {
    const [openCreateLead, setOpenCreateLead] = useState(false)
    const [proposals, setProposals] = useState([])
    const [proposalSelected, setProposalSelected] = useState([])
    const [openWinDialog, setOpenWinDialog] = useState(false)
    const [openLossDialog, setOpenLossDialog] = useState(false)
    const [totalProposals, setTotalProposals] = useState(0)
    const [deleteProposalDialog, setDeleteProposalDialog] = useState(false)
    useEffect(() => {
        getProposals()
    }, [lead])

    const getProposals = (filter = {}, limit = 25, page = 0) => {
        const offset = limit * page
        axios.post(Constants.APIEndpoints.LEADS + `/getproposalleads?limit=${limit}&offset=${offset}`, {
            fk_id_leads: lead?.id_leads,
            uuid: uuidData,
            ...filter
        }).then(res => {
            setProposals(res.data)
            setTotalProposals(res.data[0] ? res.data[0].total_count : 0)
            setOpenCreateLead(false)
        })
    }

    const winProposalLead = (values) => {
        axios.post(Constants.APIEndpoints.LEADS + '/winproposallead', {
            ...proposalSelected,
            id_leads_proposals: proposalSelected.id_leads_proposals,
            fk_id_leads: lead.id_leads
        }).then(res => {
            setOpenWinDialog(false)
            getProposals()
            eventBus.emit('update-column', {})
            getSingleLead(lead.id_leads)

        })
    }

    const lossProposalLead = (values) => {
        axios.post(Constants.APIEndpoints.LEADS + '/lossproposallead', {
            ...values,
            id_leads_proposals: proposalSelected.id_leads_proposals,
            fk_id_leads: lead.id_leads
        }).then(res => {
            setOpenLossDialog(false)
            getProposals()
            eventBus.emit('update-column', {})
            getSingleLead(lead.id_leads)
        })
    }

    const deleteProposal = () => {
        axios.delete(Constants.APIEndpoints.LEADS + '/deleteproposallead/' + proposalSelected.id_leads_proposals).then(res => {
            getProposals()
            getSingleLead(lead.id_leads)
            setDeleteProposalDialog(false)
            eventBus.emit('update-column', {})
        })
    }

    const followProposal = (id, follow) => {
        axios.post(Constants.APIEndpoints.LEADS + '/followproposallead/' + id, {
            follow
        }).then(() => {
            getProposals()
        })
    }
    const duplicateProposal = (id) => {
        const notification = toast("Buscando informações");
        axios.post(Constants.APIEndpoints.LEADS + '/duplicateproposallead/' + id).then(() => {
            toast.dismiss(notification)
            getProposals()
        })
    }

    const totalGrossValueNegotiated = proposals.reduce((sum, item) => { return sum + parseFloat(item.gross_value_negotiated || 0) }, 0)
    const totalNetValueNegotiated = proposals.reduce((sum, item) => { return sum + parseFloat(item.net_value_negotiated || 0) }, 0)
    const totalGrossValuePredicted = proposals.reduce((sum, item) => { return sum + parseFloat(item.gross_value_predicted || 0) }, 0)
    const totalNetValuePredicted = proposals.reduce((sum, item) => { return sum + parseFloat(item.net_value_predicted || 0) }, 0)
    const totalGrossValueApproved = proposals.reduce((sum, item) => { return sum + parseFloat(item.gross_value_approved || 0) }, 0)
    const totalNetValueApproved = proposals.reduce((sum, item) => { return sum + parseFloat(item.net_value_approved || 0) }, 0)
    return (
        <div>
            <ToastContainer
                position="bottom-right"
                autoClose={4500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover={false}
            />
            {
                deleteProposalDialog ? (
                    <ConfirmDialog title="Deseja deletar esta proposta?" cancel={() => setDeleteProposalDialog(false)} confirm={deleteProposal} />
                ) : null
            }
            {['in_progress'].includes(lead.status) &&  !lead.archived && (
                <div style={{ marginBottom: 5 }}>
                    <Tooltip title={<h5>Adicionar nova proposta</h5>}>
                        <IconButton
                            style={{
                                color: 'white',
                                backgroundColor: 'green'
                            }}
                            onClick={() => {
                                setOpenCreateLead(true)
                                setProposalSelected({})
                            }}
                        >
                            <Icon
                            >
                                add
                            </Icon>
                        </IconButton>
                    </Tooltip>
                </div>
            )}
            <CommonDialog
                open={openWinDialog}
                onClose={() => setOpenWinDialog(false)}
                title="Dar Ganho"
                width="md"
                fullWidth={true}
            >
                <CommonForm
                    fields={[
                        {
                            col: 12,
                            type: "file",
                            name: "file_pp",
                            label: "Arquivo PI/PP",
                            required: true

                        },
                        {
                            col: 12,
                            name: 'number',
                            type: 'text',
                            label: 'Número PI',
                            required: true,
                        },
                        {
                            col: 12,
                            name: 'gross_value_approved',
                            type: 'money',
                            label: 'Valor Bruto Aprovado',
                            required: true,
                        },
                        {
                            col: 12,
                            name: 'net_value_approved',
                            type: 'money',
                            label: 'Valor Líquido Aprovado',
                            required: true,
                        }
                    ]}
                    onChangeField={(f, v) => {
                        let newProposal = { ...proposalSelected }
                        newProposal[f.name] = v
                        if (f.name == 'gross_value_approved') {
                            newProposal.net_value_approved = v - (v * 0.2)
                        }
                        setProposalSelected(newProposal)
                    }}
                    values={proposalSelected}
                    onSubmit={winProposalLead} />
            </CommonDialog>
            <CommonDialog
                open={openLossDialog}
                onClose={() => setOpenLossDialog(false)}
                title="Dar Perda"
                width="md"
                fullWidth={true}
            >
                <CommonForm
                    fields={[
                        {
                            col: 12,
                            type: "textarea",
                            name: "justify",
                            label: "Justificativa",

                        },
                    ]}
                    values={[]}

                    onSubmit={lossProposalLead} />
            </CommonDialog>
            <CommonTable
                id={'list_proposals_lead'}
                showEditButton={!!viewMode}
                noHeader={true}
                miniVersion={true}
                pagination={getProposals}
                totalData={totalProposals}
                data={proposals}
                isSubTable={isSubTable}
                columns={[
                    {
                        accessor: "action",
                        width: 128,
                        Header: 'Ações',
                        overHeader: viewMode ? { title: 'TOTAL:', styles: { position: 'absolute', top: -10 } } : {},
                        sortable: !!viewMode,
                        noSearchable: !viewMode,
                        Cell: ({ row }) => (
                            <div>
                                {viewMode && !row.original.win && (
                                    <Tooltip title={<h5>Dar Ganho</h5>} placement="right">
                                        <IconButton
                                            onClick={(ev) => {
                                                let rowSelected = row.original
                                                setProposalSelected(rowSelected)
                                                setOpenWinDialog(true)
                                            }}
                                        >
                                            <Icon>check</Icon>
                                        </IconButton>
                                    </Tooltip>
                                )}
                                {viewMode && !row.original.loss && (
                                    <Tooltip title={<h5>Dar Perda</h5>} placement="right">
                                        <IconButton
                                            onClick={(ev) => {
                                                let rowSelected = row.original
                                                setProposalSelected(rowSelected)
                                                setOpenLossDialog(true)
                                            }}
                                        >
                                            <Icon>close</Icon>
                                        </IconButton>
                                    </Tooltip>
                                )}
                                <>
                                    {row.original.fk_id_responsable == user.id_user && (
                                        <Tooltip title={<h5>{row.original.follow ? 'Dar Unfollow' : 'Dar Follow'}</h5>} placement="right">
                                            <IconButton
                                                onClick={(ev) => {
                                                    const follow = row.original.follow ? false : true
                                                    followProposal(row.original.id_leads_proposals, follow)
                                                }}
                                            >
                                                {row.original.follow ? <PersonAddDisabledIcon /> : <PersonAddIcon />}
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    <Tooltip title={<h5>Editar a Proposta</h5>} placement="right">
                                        <IconButton
                                            onClick={(ev) => {
                                                setOpenCreateLead(true)
                                                setProposalSelected(row.original)
                                            }}
                                        >
                                            <Icon>edit</Icon>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={<h5>Excluir a Proposta</h5>} placement="right">
                                        <IconButton
                                            onClick={(ev) => {
                                                setProposalSelected(row.original)
                                                setDeleteProposalDialog(true)
                                            }}
                                        >
                                            <Icon>delete</Icon>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={<h5>Duplicar a Proposta</h5>} placement="right">
                                        <IconButton
                                            onClick={(ev) => {
                                                duplicateProposal(row.original.id_leads_proposals)
                                            }}
                                        >
                                            <Icon>content_copy</Icon>
                                        </IconButton>
                                    </Tooltip>

                                </>
                            </div >
                        ),
                    },
                    {
                        Header: "Status",
                        accessor: "status",
                        className: "font-medium",
                        sortable: !!viewMode,
                        noSearchable: !viewMode,
                        Cell: ({ row }) => (
                            <div style={{ color: row.original.win ? 'green' : row.original.loss ? 'red' : 'black' }}>
                                {row.original.win ? 'Ganho' : row.original.loss ? 'Perda' : 'Em Andamento'}
                            </div>
                        )
                    },
                    {
                        Header: "Data de Modificação",
                        accessor: "dt_up",
                        className: "font-medium",
                        sortable: !!viewMode,
                        noSearchable: !viewMode,
                        Cell: ({ row }) => (
                            <div style={{ color: row.original.win ? 'green' : row.original.loss ? 'red' : 'black' }}>
                                {row.original.dt_up ? moment(row.original.dt_up).format('DD/MM/YYYY HH:mm:ss') : ''}
                            </div>
                        )
                    },
                    {
                        Header: "Veículo",
                        accessor: "vehicle",
                        sortable: !!viewMode,
                        noSearchable: !viewMode,
                        Cell: ({ row }) => (
                            <div style={{ color: row.original.win ? 'green' : row.original.loss ? 'red' : 'black' }}>
                                {row.original.vehicle}
                            </div>
                        )
                    },
                    {
                        Header: "Produto",
                        accessor: "product",
                        sortable: !!viewMode,
                        noSearchable: !viewMode,
                        Cell: ({ row }) => (
                            <div style={{ color: row.original.win ? 'green' : row.original.loss ? 'red' : 'black' }}>
                                {row.original.product}
                            </div>
                        )
                    },
                    {
                        Header: "Campanha",
                        accessor: "campaign",
                        sortable: !!viewMode,
                        noSearchable: !viewMode,
                        Cell: ({ row }) => (
                            <div style={{ color: row.original.win ? 'green' : row.original.loss ? 'red' : 'black' }}>
                                {row.original.campaign}
                            </div>
                        )
                    },
                    {
                        Header: "Agência",
                        accessor: "agency",
                        sortable: !!viewMode,
                        noSearchable: !viewMode,
                        Cell: ({ row }) => (
                            <div style={{ color: row.original.win ? 'green' : row.original.loss ? 'red' : 'black' }}>
                                {row.original.agency}
                            </div>
                        )
                    },
                    {
                        Header: "PI",
                        accessor: "number",
                        className: "font-medium",
                        sortable: !!viewMode,
                        noSearchable: !viewMode,
                        Cell: ({ row }) => (
                            <div style={{ color: row.original.win ? 'green' : row.original.loss ? 'red' : 'black' }}>
                                {row.original.number}
                            </div>
                        )
                    },
                    {
                        Header: "Data do Vencimento / Perda",
                        accessor: "dt_win",
                        className: "font-medium",
                        sortable: !!viewMode,
                        noSearchable: !viewMode,
                        Cell: ({ row }) => (
                            <div style={{ color: row.original.win ? 'green' : row.original.loss ? 'red' : 'black' }}>
                                {row.original.dt_win ? moment(row.original.dt_win).format('DD/MM/YYYY HH:mm:ss') : row.original.dt_loss ? moment(row.original.dt_loss).format('DD/MM/YYYY HH:mm:ss') : ''}
                            </div>
                        )
                    },
                    {
                        Header: "Executivo",
                        accessor: "responsable",
                        className: "font-medium",
                        sortable: !!viewMode,
                        noSearchable: !viewMode,
                        Cell: ({ row }) => (
                            <div style={{ color: row.original.win ? 'green' : row.original.loss ? 'red' : 'black' }}>
                                {row.original.responsable}
                            </div>
                        )
                    },

                    {
                        Header: "Representante",
                        accessor: "sponsor_vehicle",
                        sortable: !!viewMode,
                        noSearchable: !viewMode,
                        Cell: ({ row }) => (
                            <div style={{ color: row.original.win ? 'green' : row.original.loss ? 'red' : 'black' }}>
                                {row.original.sponsor_vehicle}
                            </div>
                        )
                    }, {
                        Header: "Data de Início",
                        accessor: "dt_start",
                        sortable: !!viewMode,
                        noSearchable: !viewMode,
                        Cell: ({ row }) => (
                            <div style={{ color: row.original.win ? 'green' : row.original.loss ? 'red' : 'black' }}>
                                {row.original.dt_start ? moment(row.original.dt_start).format('DD/MM/YYYY') : ''}
                            </div>
                        )
                    },
                    {
                        Header: "Data de Término",
                        accessor: "dt_end",
                        sortable: !!viewMode,
                        noSearchable: !viewMode,
                        Cell: ({ row }) => (
                            <div style={{ color: row.original.win ? 'green' : row.original.loss ? 'red' : 'black' }}>
                                {row.original.dt_end ? moment(row.original.dt_end).format('DD/MM/YYYY') : ''}
                            </div>
                        )
                    },
                    {
                        Header: "Valor Bruto Negociado",
                        accessor: "gross_value_negotiated",
                        sortable: !!viewMode,
                        noSearchable: !viewMode,
                        overHeader: viewMode ? { title: parseFloat(totalGrossValueNegotiated).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }), styles: { position: 'absolute', top: 5 } } : {},
                        Cell: ({ row }) => (
                            <div style={{ color: row.original.win ? 'green' : row.original.loss ? 'red' : 'black' }}>
                                {parseFloat(row.original.gross_value_negotiated || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                            </div>
                        )
                    },
                    {
                        Header: "Valor Líquido Negociado",
                        accessor: "net_value_negotiated",
                        sortable: !!viewMode,
                        noSearchable: !viewMode,
                        overHeader: viewMode ? { title: parseFloat(totalNetValueNegotiated).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }), styles: { position: 'absolute', top: 5 } } : {},
                        Cell: ({ row }) => (
                            <div style={{ color: row.original.win ? 'green' : row.original.loss ? 'red' : 'black' }}>
                                {parseFloat(row.original.net_value_negotiated || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                            </div>
                        )
                    },
                    {
                        Header: "Valor Bruto Previsto",
                        accessor: "gross_value_predicted",
                        sortable: !!viewMode,
                        noSearchable: !viewMode,
                        overHeader: viewMode ? { title: parseFloat(totalGrossValuePredicted).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }), styles: { position: 'absolute', top: 5 } } : {},
                        Cell: ({ row }) => (
                            <div style={{ color: row.original.win ? 'green' : row.original.loss ? 'red' : 'black' }}>
                                {parseFloat(row.original.gross_value_predicted || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                            </div>
                        )
                    },
                    {
                        Header: "Valor Líquido Previsto",
                        accessor: "net_value_predicted",
                        sortable: !!viewMode,
                        noSearchable: !viewMode,
                        overHeader: viewMode ? { title: parseFloat(totalNetValuePredicted).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }), styles: { position: 'absolute', top: 5 } } : {},
                        Cell: ({ row }) => (
                            <div style={{ color: row.original.win ? 'green' : row.original.loss ? 'red' : 'black' }}>
                                {parseFloat(row.original.net_value_predicted || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                            </div>
                        )
                    },
                    {
                        Header: "Valor Bruto Aprovado",
                        accessor: "gross_value_approved",
                        sortable: !!viewMode,
                        noSearchable: !viewMode,
                        overHeader: viewMode ? { title: parseFloat(totalGrossValueApproved).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }), styles: { position: 'absolute', top: 5 } } : {},
                        Cell: ({ row }) => (
                            <div style={{ color: row.original.win ? 'green' : row.original.loss ? 'red' : 'black' }}>
                                {parseFloat(row.original.gross_value_approved || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                            </div>
                        )
                    },
                    {
                        Header: "Valor Líquido Aprovado",
                        accessor: "net_value_approved",
                        sortable: !!viewMode,
                        noSearchable: !viewMode,
                        overHeader: viewMode ? { title: parseFloat(totalNetValueApproved).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }), styles: { position: 'absolute', top: 5 } } : {},
                        Cell: ({ row }) => (
                            <div style={{ color: row.original.win ? 'green' : row.original.loss ? 'red' : 'black' }}>
                                {parseFloat(row.original.net_value_approved || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                            </div>
                        )
                    },
                    {
                        Header: "Observações",
                        accessor: "observation",
                        sortable: !!viewMode,
                        noSearchable: !viewMode,
                        Cell: ({ row }) => (
                            <div style={{ color: row.original.win ? 'green' : row.original.loss ? 'red' : 'black' }}>
                                {(row.original.observation || '').replace(/<[^>]*>?/gm, '')}
                            </div>
                        )
                    },
                    {
                        Header: "Justificativa de Perda",
                        accessor: "justify",
                        sortable: !!viewMode,
                        noSearchable: !viewMode,
                        Cell: ({ row }) => (
                            <div style={{ color: row.original.win ? 'green' : row.original.loss ? 'red' : 'black' }}>
                                {(row.original.justify || '').replace(/<[^>]*>?/gm, '')}
                            </div>
                        )
                    },

                ]}
            />
            {
                openCreateLead == true && (
                    <CreateProposalLead
                        open={openCreateLead}
                        users={users}
                        vehicles={vehicles}
                        proposalSelected={proposalSelected}
                        setProposalSelected={setProposalSelected}
                        isEditMode={!!proposalSelected.id_leads_proposals}
                        uuidData={uuidData}
                        getProposals={getProposals}
                        lead={lead}
                        agencies={agencies}
                        onClose={() => {
                            setOpenCreateLead(false)
                            // setSingleContact([])
                            getSingleLead(lead.id_leads)
                        }}
                    />
                )
            }
        </div >
    )
}

export default List