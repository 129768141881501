import CommonForm from "app/components/form/CommonForm";
import CommonHeader from "app/components/table/CommonHeader";

import React, { useState, useEffect } from 'react'
import Store from 'app/utils/Store'
import axios from 'app/utils/AxiosConfig';
import Constants from "app/utils/Constants";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import './Dashboard.css'
import moment from "moment-timezone";
import Chart from 'react-apexcharts';
import { useResizeDetector } from 'react-resize-detector';


import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import DashboardDetail from "./DashboardDetail";
import DashboardGeneral from "./DashboardGeneral";
import Comparatives from "./Comparatives";
import OwnDash from "./OwnDash";
export default function Dashboard() {
    const { width, height, ref } = useResizeDetector();
    const [pageActive, setPageActive] = useState(3)

    return (
        <div ref={ref}>
            <CommonHeader title=''
                width={width}
                onlyHeader={true}
                miniVersion={true}
                headerFilters={(
                    <div className="btnHeaders"
                    >
                        <div
                            onClick={() => setPageActive(1)}
                            style={{
                                backgroundColor: pageActive == 1 ? '#aba6a6' : ''
                            }}
                        >VISÃO GERAL</div>
                        {/* <div
                            onClick={() => setPageActive(2)}
                            style={{
                                backgroundColor: pageActive == 2 ? '#aba6a6' : ''
                            }}
                        >COMPARATIVOS</div> */}
                        <div
                            onClick={() => setPageActive(3)}
                            style={{
                                backgroundColor: pageActive == 3 ? '#aba6a6' : ''
                            }}
                        >DASHBOARDS</div>
                    </div>
                )
                }
            />
            < div>
                {pageActive == 0 ? (
                    <DashboardDetail />
                ) :
                    pageActive == 1 ?
                        (
                            <DashboardGeneral />
                        ) : pageActive == 2 ?
                            (
                                <Comparatives />
                            ) : <OwnDash />
                }
            </div >
        </div >
    )
}