import React, { useState, useEffect, useRef } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import Chart from "react-apexcharts";
import { Card, Divider, Icon, IconButton } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import "react-grid-layout/css/styles.css";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import CommonDialog from "app/components/dialog/CommonDialog";
import CommonForm from "app/components/form/CommonForm";
import Constants from "app/utils/Constants";
import axios from "app/utils/AxiosConfig";
import ChartsData from "./ChartsData";
import ConfirmDialog from "app/components/dialog/ConfirmDialog";
import "./Dashboard.css";
import OwnDashSidebar from "./OwnDashSidebar";
import { useResizeDetector } from "react-resize-detector";
import moment from "moment";
import CommonTable from "app/components/table/CommonTable";
import { useDispatch, useSelector } from 'react-redux';
import Store from "app/utils/Store";
import { navbarToggleMobile, navbarToggle } from '../../store/fuse/navbarSlice';
import { saveAs } from 'file-saver';
import { toast } from "react-toastify";

import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { options } from "@fullcalendar/core/preact";
const user = Store.USER
const ResponsiveReactGridLayout = WidthProvider(Responsive);

const OwnDash = ({ }) => {
  const [openCreateGraph, setOpenCreateGraph] = useState(false);
  const { innerWidth: width } = window;
  const containerRef = useRef()
  const dispatch = useDispatch();
  const isMenuOpen = useSelector((state) => state.fuse.navbar.open);

  const [users, setUsers] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [clients, setClients] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const [tags, setTags] = useState([]);
  const [status, setStatus] = useState([]);
  const [groups, setGroups] = useState([]);
  const [squares, setSquares] = useState([]);
  const [justifies, setJustifies] = useState([]);
  const [teams, setTeams] = useState([])

  const [graph, setGraph] = useState({});
  const [allData, setAllData] = useState([]);
  const [layout, setlayout] = useState([]);
  const [isOverTrash, setIsOverTrash] = useState(false);
  const [openTrashZone, setOpenTrashZone] = useState(false);
  const [itemMoving, setItemMoving] = useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDashSidebar, setOpenDashSidebar] = useState(false);
  const [dashes, setDashes] = useState([]);
  const [dashesToAdd, setDashesToAdd] = useState([]);

  const [groupsDashes, setGroupsDashes] = useState([]);

  const [selectedDash, setSelectedDash] = useState([]);
  const [idDashSelected, setIdDashSelected] = useState(0);
  const [dashItensToRender, setDashItensToRender] = useState([]);
  const [editMode, setEditMode] = useState(false)

  const [colsLayout, setColsLayout] = useState({
    lg: 12,
    md: 10,
    sm: 6,
    xs: 4,
    xxs: 2
  })
  const [hoveredDiv, setHoveredDiv] = useState(null);


  const downloadTableGraph = (graph, type, extension) => {
    const name = 'teste'
    const file = `${name}-${moment().format('DDMMYYYY_HH:mm:ss')}.${extension}`
    const token = sessionStorage.getItem('token');

    fetch(Constants.APIEndpoints.DASHBOARD + '/downloadowndash',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Access-Token': token
        },
        body: JSON.stringify({
          ...graph,
          type: type,
          extension: extension
        })
      }).then(results => {
        return results.blob()
      }).then(response => {
        saveAs(response, file);
      })
  }

  useEffect(() => {
    // if (!isNaN(width))
    //   setColsLayout({
    //   lg : width / 168,
    //   md: 10, 
    //   sm: 6, 
    //   xs: 4, 
    //   xxs: 2 
    // })
  }, [width])

  useEffect(() => {
    dispatch(navbarToggle());

    getDashes();
    getDashesToAdd();

    axios
      .post(Constants.APIEndpoints.PROPOSAL + "/getallgeneraldata", {
        entities: ["agencies", "teams", "users", "clients", "vehicles", "tags", "status", "groups", "squares", "justifies"],
      })
      .then((res) => {
        setAgencies(res.data.agencies);
        setUsers(res.data.users);
        setClients(res.data.clients);
        setVehicles(res.data.vehicles);
        setTags(res.data.tags);
        setStatus(res.data.status);
        setGroups(res.data.groups);
        setTeams(res.data.teams)
        setSquares(res.data.squares);
        setJustifies(res.data.justifies);
      });
  }, []);

  const onLayoutChange = (layout) => {
    setlayout(layout);
    axios.post(Constants.APIEndpoints.USER + "/updateusergraph", layout);
  };

  const getDashesToAdd = () => {
    axios.get(Constants.APIEndpoints.DASHBOARD + "/getdashes?group=false").then((res) => {
      setDashesToAdd(res.data.dashes)
    });
  }


  const getDashes = () => {
    axios.get(Constants.APIEndpoints.DASHBOARD + "/getdashes").then((res) => {
      setDashes(res.data.dashes)
      setGroupsDashes(res.data.groups)
      if (idDashSelected == 0) {
        getDataGraph(res.data[0]?.id_dash ?? 0);
      }
    });
  };

  const getDataGraph = (idDash, dash) => {
    setIdDashSelected(idDash);
    axios.post(Constants.APIEndpoints.USER + "/getusergraphs", { fk_id_dash: idDash }).then((res) => {
      let newLayouts = [];
      setDashItensToRender(res.data);
      res.data.map((data, index) => {
        let layout = JSON.parse(data.layout);
        layout.i = data.id_dash_itens.toString();
        newLayouts.push(layout);
        if ([3, 4, 5, 6, 7, 8].includes(data.type)) {
          getData({ ...JSON.parse(data.graph), ...layout }, dash);
        }
      });
      setlayout(newLayouts);
    });
  };


  const getNextPositionGrid = (newItem) => {
    const positions = new Set();
    layout.forEach((item) => {
      for (let i = 0; i < item.w; i++) {
        for (let j = 0; j < item.h; j++) {
          positions.add(`${item.x + i}:${item.y + j}`);
        }
      }
    });

    let x = newItem.x || 0;
    let y = newItem.y || 0;
    while (positions.has(`${x}:${y}`)) {
      x++;
      if (x + newItem.w > 12) {
        x = 0;
        y++;
        if (y + newItem.h > 30) {
          break;
        }
      }
    }
    return { x, y };
  };

  const createGraph = () => {
    const uuidData = uuidv4();
    const widths = {
      1: 4,
      2: 12,
      3: 6,
      4: 6,
      5: 3,
      6: 6,
      7: 6,
      8: 3
    }

    const heigths = {
      1: 4,
      2: 1,
      3: 4,
      4: 18,
      5: 4,
      6: 4,
      7: 18,
      8: 4
    }
    const width = widths[graph.type]
    const height = heigths[graph.type];
    let newItem = { i: graph.title, w: width, h: height };
    const newItemToLayout = getNextPositionGrid(newItem);
    newItem.x = newItemToLayout.x;
    newItem.y = newItemToLayout.y;

    if (!graph.metrics && graph.type == 6 && graph.type_graph == 2 && graph.type_leads == 1) {
      graph.metrics = ['value']
      graph.groupby = graph.fields_nominals_leads[0]
    }

    const url = graph.id_dash_itens ? `${Constants.APIEndpoints.DASHBOARD}/updatedashitem` : `${Constants.APIEndpoints.USER}/saveusergraph`;
    const data = {
      uuid: uuidData,
      fk_id_dash: idDashSelected,
      type: graph.type,
      graph: [3, 4, 5, 6, 7, 8].includes(graph.type) ? { data: graph } : graph,
      layout: JSON.stringify(newItem),
    };


    axios.post(url, data).then((res) => {
      setOpenCreateGraph(false);
      getDataGraph(idDashSelected, selectedDash);
    });
  };

  const getData = (filters, dash) => {
    if (dash?.dt_end && dash.dt_end !== 'Invalid date') filters.dt_end = dash.dt_end
    if (dash?.dt_start && dash.dt_end !== 'Invalid date') filters.dt_start = dash.dt_start
    if (dash?.fk_id_agency) filters.fk_id_agency = dash.fk_id_agency
    if (dash?.fk_id_vehicle) filters.fk_id_vehicle = dash.fk_id_vehicle
    if (dash?.fk_id_square) filters.fk_id_square = dash.fk_id_square
    if (dash?.fk_id_group) filters.fk_id_group = dash.fk_id_group
    if (dash?.fk_id_client) filters.fk_id_client = dash.fk_id_client
    if (dash?.fk_id_responsable) filters.fk_id_responsable = dash.fk_id_responsable


    axios.post(Constants.APIEndpoints.DASHBOARD + "/getowndashdata", filters).then((res) => {
      setAllData((prev) => prev.filter((p) => p.id_dash_itens != filters.i));
      if (filters.type_graph == 1) {
        const data = res.data[0];
        data.name = filters.name;
        data.isCard = true;
        data.id_dash_itens = filters.i;
        data.returnvalue = (filters.returnvalue || '').replace(/<[^>]*>?/gm, '').length ? filters.returnvalue : undefined
        data.value = data[filters.metrics] || data[filters?.card_value];
        setAllData((prev) => [...prev, data]);
      } else if ([4, 7].includes(filters.type)) {
        const data = { data: res.data };
        data.id_dash_itens = filters.i;
        data.name = filters.name;
        data.sum = buildSum(res.data, filters.fields_values || filters.fields_values_proposals || filters.fields_values_leads);
        data.columns = buildColumns(filters.fields_nominals || filters.fields_nominals_leads || filters.fields_nominals_leads_proposals, filters.fields_values || filters.fields_values_proposals || filters.fields_values_leads);
        data.underHeader = buildUnderHeader(data.sum, filters.fields_nominals || filters.fields_nominals_leads || filters.fields_nominals_leads_proposals, filters.fields_values || filters.fields_values_proposals || filters.fields_values_leads);
        setAllData((prev) => [...prev, data]);
      } else if ([5, 8].includes(filters.type)) {
        const data = {
          value: res.data,
          id_dash_itens: filters.i,
          name: filters.name,
          returnvalue: filters.returnvalue
        };
        setAllData((prev) => [...prev, data]);

      }
      else {
        const data = ChartsData.formatDataForColumnChart(res.data, filters, filters.type_graph);
        data.id_dash_itens = filters.i;
        data.name = filters.name;
        setAllData((prev) => [...prev, data]);
      }
    });
  };

  const buildUnderHeader = (sum, nominals, values) => {
    const totalColumns = nominals.length + values.length;
    const percent = `${parseInt(100 / totalColumns)}%`;

    return (
      <div style={{ display: "flex", marginTop: 10, fontSize: 17 }}>
        {nominals.map((n, index) =>
          index == 0 ? <div style={{ width: percent, fontWeight: "bold" }}>Soma:</div> : <div style={{ width: percent }}></div>
        )}
        {Object.keys(sum).map((o) => (
          <div style={{ width: percent, fontWeight: "bold" }}>
            {parseFloat(sum[o]).toLocaleString("pt-br", { style: "currency", currency: "BRL" })}
          </div>
        ))}
      </div>
    );
  };

  const buildSum = (data, fields) => {
    return data.reduce((acc, obj) => {
      fields.forEach((key) => {
        if (obj[key] !== undefined) {
          acc[key] = (acc[key] || 0) + parseFloat(obj[key] || 0);
        }
      });
      return acc;
    }, {});
  };

  const buildColumns = (nominals, values) => {
    const translateObjectHeader = {
      dt_start: "Data de Início",
      dt_emission: "Data de Ganho",
      "tv.fancy_name": "Veículo",
      "ta.fancy_name": "Agência",
      "tc.fancy_name": "Cliente",
      "tp.sponsor_vehicle": "Representante",
      "tg.name": "Grupo de Mídia",
      net_value_approved: "Valor Líquido Faturado",
      approved_gross_value: "Valor Bruto Faturado",
      net_value_proposal: "Valor Líquido Aprovado",
      gross_value_proposal: "Valor Bruto Aprovado",
      received_value: "Valor Recebido",
      comission_value: "Valor da Comissão",
      "tp.fk_id_responsable": "Executivo",
      "tp.number": "Número PI",
      "net_value_negotiated": "Valor Líquido Negociado",
      "gross_value_negotiated": "Valor Bruto Negociado",
      "gross_value_predicted": "Valor Bruto Previsto",
      "net_value_predicted": "Valor Líquido Previsto",
      "gross_value_approved": "Valor Bruto Aprovado",
      "net_value_approved": "Valor Líquido Aprovado",
      "value": "Valor"
    };

    const translateObjectAccessor = {
      dt_start: "dt_start",
      dt_emission: "dt_emission",
      "tv.fancy_name": "vehicle",
      "ta.fancy_name": "agency",
      "tc.fancy_name": "client",
      "tg.name": "group",
      "tp.sponsor_vehicle": "sponsor_vehicle",
      net_value_approved: "net_value_approved",
      approved_gross_value: "approved_gross_value",
      net_value_proposal: "net_value_proposal",
      gross_value_proposal: "gross_value_proposal",
      received_value: "received_value",
      comission_value: "comission_value",
      "tp.fk_id_responsable": "fk_id_responsable",
      "tp.number": "number",
      "net_value_negotiated": "Valor Líquido Negociado",
      "gross_value_negotiated": "Valor Bruto Negociado",
      "gross_value_predicted": "Valor Bruto Previsto",
      "net_value_predicted": "Valor Líquido Previsto",
      "gross_value_approved": "Valor Bruto Aprovado",
      "net_value_approved": "Valor Líquido Aprovado",
      "value": "Valor"
    };
    let cols = [];
    nominals.map((nominal) => {
      cols.push({
        Header: translateObjectHeader[nominal],
        accessor: translateObjectAccessor[nominal],
        className: "font-medium",
        sortable: true,
        noSearchable: true,
      });
    });

    values.map((value) => {
      cols.push({
        Header: translateObjectHeader[value],
        accessor: translateObjectAccessor[value],
        className: "font-medium",
        sortable: true,
        noSearchable: true,
        Cell: ({ row }) => (
          <div>{parseFloat(row.original[value] ?? 0).toLocaleString("pt-br", { style: "currency", currency: "BRL" })}</div>
        ),
      });
    });
    return cols;
  };

  const TrashZone = ({ openTrashZone }) => {
    return (
      <div></div>
      // <div id='trash-zone' className="trashzone" style={{ display: openTrashZone ? 'block' : 'none' }}>
      //     <IconButton
      //         color="white">
      //         <Icon>delete</Icon>
      //     </IconButton>
      // </div>
    );
  };

  const removeGraph = () => {
    setOpenDeleteDialog(false);
    axios.post(Constants.APIEndpoints.USER + "/deleteusergraph", itemMoving).then((res) => {
      getDataGraph(idDashSelected, selectedDash);
      setOpenTrashZone(false);
    });
  };

  const handleDrag = (layout, oldItem, newItem, placeholder, e, element) => {
    // setOpenTrashZone(true)
    // setItemMoving(oldItem)
    // const trashZone = document.getElementById('trash-zone');
    // const trashZoneRect = trashZone.getBoundingClientRect();
    // const elementRect = element.getBoundingClientRect();
    // const isOverTrash =
    //     elementRect.bottom - 100 >= trashZoneRect.top &&
    //     elementRect.top <= trashZoneRect.bottom &&
    //     elementRect.right >= trashZoneRect.left &&
    //     elementRect.left <= trashZoneRect.right;

    //  setIsOverTrash(isOverTrash);
    setIsOverTrash(false);
  };

  const onDragStop = (element) => {
    if (isOverTrash) {
      setOpenDeleteDialog(true);
    } else {
      setOpenTrashZone(false);
      setItemMoving({});
    }
  };

  const loadDash = (dash) => {
    setAllData([]);
    setIdDashSelected(dash.id_dash);
    getDataGraph(dash.id_dash, dash);
    setSelectedDash(dash);
  };

  const onEdit = (item) => {
    const itemToRender = dashItensToRender.find((d) => d.id_dash_itens == item.i);
    let editGraph = JSON.parse(itemToRender.graph);
    if (!!editGraph || editGraph.length == 0) {
      if (itemToRender.type == 2) {
        editGraph = {
          type: itemToRender.type,
          text: itemToRender.text,
        };
      }
      if (itemToRender.type == 1) {
        editGraph = {
          text: itemToRender.text,
          image: {
            data: itemToRender.image,
          },
          type: itemToRender.type,
        };
      }
    }
    editGraph.id_dash_itens = item.i;
    setGraph(editGraph);
    setOpenCreateGraph(true);
  };

  const onRemove = (item) => {
    setItemMoving(item);
    setOpenDeleteDialog(true);
  };

  const showList = (data, item) => {
    if (data.find((d) => d.id_dash_itens == item.i)?.hasOwnProperty("data")) {
      return true;
    } else {
      return false;
    }
  };

  const getFormattedValue = (text, value) => {
    return text.replace('$return', parseFloat(value).toFixed(2))
  }

  return (
    <div ref={containerRef} style={{ backgroundColor: "white", marginTop: 70, width: width }}>
      {/* <Canvas /> */}
      <CommonDialog
        open={openCreateGraph}
        onClose={() => {
          setGraph({});
          setOpenCreateGraph(false);
        }}
        title={"Criar Novo Gráfico"}
        fullWidth={true}
        width={"md"}
      >
        <CommonForm
          fields={[
            {
              col: 12,
              type: "select",
              name: "type",
              label: "Tipo",
              options: [
                { value: 1, label: "Imagem" },
                { value: 2, label: "Texto" },
                { value: 3, label: "Gráfico dos Negócios" },
                { value: 4, label: "Listagem dos Negócios" },
                { value: 5, label: "Fórmula dos Negócios" },
                { value: 6, label: "Gráfico dos Leads" },
                { value: 7, label: "Listagem dos Leads" },
                // { value: 8, label: "Fórmula dos Leads" },

              ],
            },
            {
              col: 12,
              type: 'text',
              label: 'Nome',
              name: 'name',
              visible: [5, 8].includes(graph?.type)
            },
            {
              col: 12,
              type: 'textEditorTags',
              useTags: true,
              helperText: `
              <p>Digite $ para visualizar as tags e selecioná-las para a criação da formula. <br>
              Segue alguns exemplos de como usar o SUMIFS para adequar a fórmula a necessidade: <br>
              Ex 01: SUMIFS($comission_value, $sponsor == 'MOSAICO') + SUMIFS($comission_value, $sponsor != 'ADTARGET') + SUMIFS($net_value_approved, $groupname == 'DIGITAIS') <br>
              Ex 02: $gross_value_approved - ( SUMIFS($comission_value, $sponsor == 'MOSAICO') + SUMIFS($comission_value, $sponsor == 'ADTARGET') + SUMIFS($net_value_approved, $groupname == 'DIGITAIS') + 1001 ) <br>
              O primeiro valor é o que será considerado na soma, o segundo é a condição </p>
              `,
              label: 'Fórmula',
              name: 'formula',
              visible: [5, 8].includes(graph?.type)
            },
            {
              col: 12,
              type: 'textEditorTags',
              onlyReturn: true,
              label: 'Formate o retorno',
              name: 'returnvalue',
              visible: [5, 6, 8].includes(graph?.type) && graph?.type_graph == 1
            },
            {
              type: "image",
              label: "Image",
              name: "image",
              visible: graph?.type == 1,
              src: graph?.image?.data,
            },
            {
              col: 12,
              type: "textEditor",
              name: "text",
              label: "Texto",
              visible: graph?.type == 2,
            },
            {
              col: 12,
              type: "select",
              name: "type_graph",
              label: "Tipo de Gráfico",
              options: [
                { value: 1, label: "Card" },
                { value: 2, label: "Barra" },
                { value: 3, label: "Coluna" },
              ],
              visible: [3, 6].includes(graph?.type),
            },
            {
              col: 12,
              type: "select",
              name: "type_leads",
              label: "Selecione os quais dados dos leads usar",
              options: [
                { value: 1, label: "Leads" },
                { value: 2, label: "Propostas" },
                //   { value: 3, label: "Atividadeds" },
              ],
              visible: [6, 7, 8].includes(graph?.type),
            },
            {
              col: 12,
              name: "name",
              type: "text",
              label: "Título",
              visible: [3, 4, 6, 7].includes(graph?.type),
            },
            {
              col: 12,
              name: "card_value",
              type: 'select',
              label: "Valor",
              options: [
                { value: 'percent', label: 'Porcentagem sobre o total' },
                { value: 'total_value', label: 'Valor Total' },
                { value: 'quantity', label: 'Quantidade' },
              ],
              visible: graph?.type == 6 && graph?.type_graph == 1
            },
            {
              col: 6,
              type: "select",
              label: "Campos de Valores",
              name: "value_proposal",
              visible: graph?.type_graph == 1 && graph?.type_leads == 2 && graph?.type == 6 && graph?.card_value == 'total_value',
              options: [
                { value: "net_value_approved", label: "Valor Líquido Faturado" },
                { value: "approved_gross_value", label: "Valor Bruto Faturado" },
                { value: "net_value_proposal", label: "Valor Líquido Aprovado" },
                { value: "gross_value_proposal", label: "Valor Bruto Aprovado" },
                { value: "received_value", label: "Valor Recebido" },
                { value: "comission_value", label: "Valor da Comissão" },
              ],
            },
            {
              col: 8,
              name: 'metrics_leads',
              label: 'Métricas',
              type: 'multiselect',
              options: [
                { value: "gross_value_negotiated", label: "Valor Bruto Negociado" },
                { value: "net_value_negotiated", label: "Valor Líquido Negociado" },
                { value: "gross_value_predicted", label: "Valor Bruto Previsto" },
                { value: "net_value_predicted", label: "Valor Líquido Previsto" },
                { value: "gross_value_approved", label: "Valor Bruto Aprovado" },
                { value: "net_value_approved", label: "Valor Líquido Aprovado" },
              ],
              visible: graph?.type_leads == 2 && graph?.type_graph !== 1 && graph?.type !== 7
            },
            {
              col: 4,
              name: "groupby",
              label: "Agrupar Por",
              visible: graph?.type_leads == 2 && graph?.type_graph !== 1 && graph?.type !== 7,
              type: "select",
              options: [
                { value: "dt_start", label: "Data de Início" },
                { value: "tv.fancy_name", label: "Veículo" },
                { value: "ta.fancy_name", label: "Agência" },
                { value: "tp.sponsor_vehicle", label: "Representante" },
                { value: "tg.name", label: "Grupo de Mídia" },
                { value: "tp.fk_id_responsable", label: "Executivo" },

              ],
            },
            {
              col: 6,
              type: "multiselect",
              label: "Campos nominais",
              name: "fields_nominals_leads",
              visible: graph?.type_leads == 1 && graph?.type_graph !== 1,
              options: [
                { value: "ta.fancy_name", label: "Agência" },
                { value: "tc.fancy_name", label: "Cliente" },
                { value: "dt_start", label: "Data de Início" },
                //  { value: "tl.dt_end", label: "Data de Término" },

              ],
            },
            {
              col: 6,
              type: "multiselect",
              label: "Campos nominais",
              name: "fields_nominals_leads_proposals",
              visible: graph?.type_leads == 2 && graph?.type_graph !== 1,
              options: [
                { value: "tv.fancy_name", label: "Veículo" },
                { value: "ta.fancy_name", label: "Agência" },
                { value: "tp.sponsor_vehicle", label: "Representante" },
                { value: "tg.name", label: "Grupo de Mídia" },
                { value: "tp.fk_id_responsable", label: "Executivo" },
              ],
            },
            {
              col: graph?.metrics == "goal" ? 6 : 12,
              type: graph?.type_graph == 1 ? "select" : "multiselect",
              name: "metrics",
              label: graph?.type_graph == 1 ? "Métrica" : "Métricas",
              options: [
                { value: "net_value_approved", label: "Valor Líquido Faturado" },
                { value: "approved_gross_value", label: "Valor Bruto Faturado" },
                { value: "net_value_proposal", label: "Valor Líquido Aprovado" },
                { value: "gross_value_proposal", label: "Valor Bruto Aprovado" },
                { value: "received_value", label: "Valor Recebido" },
                { value: "comission_value", label: "Valor da Comissão" },
                { value: "goal", label: "Meta" },
              ],
              visible: graph?.type == 3,
            },
            {
              col: 6,
              name: "goal",
              label: "Meta",
              type: "select",
              visible: graph?.metrics == "goal",
              options: [
                { value: "total_goal", label: "Total da meta do ano" },
                { value: "relative_goal", label: "Total da meta no período" },
                //  { value: 'percent_goal', label: '% Da meta alcançada' },
                //  { value: 'left_goal', label: 'Valor restante da meta' },
              ],
            },
            {
              col: graph?.period == 4 ? 3 : 6,
              type: "select",
              label: "Dimensão de Tempo",
              name: "dimension",
              visible: ([3, 4, 5].includes(graph?.type)) && selectedDash?.date_flexible != true,
              options: [
                { value: "dt_emission", label: "Data de Ganho" },
                { value: "dt_start", label: "Data de Início" },
              ],
            },
            {
              col: graph?.period == 4 ? 3 : 6,
              type: "select",
              label: "Período",
              name: "period",
              visible: ([3, 4, 5, 6, 7, 8].includes(graph?.type)) && selectedDash?.date_flexible != true,
              options: [
                { value: 1, label: "Semana atual" },
                { value: 2, label: "Mês Atual" },
                { value: 3, label: "Ano Atual" },
                { value: 4, label: "Personalizado" },
              ],
            },
            {
              col: 3,
              type: "date",
              name: "dt_start",
              label: "De",
              visible: graph?.period == 4,
            },
            {
              col: 3,
              type: "date",
              name: "dt_end",
              label: "Até",
              visible: graph?.period == 4,
            },
            {
              col: 6,
              type: "multiselect",
              label: "Campos nominais",
              name: "fields_nominals",
              visible: graph?.type == 4,
              options: [
                { value: "dt_start", label: "Data de Início" },
                { value: "dt_emission", label: "Data de Ganho" },
                { value: "tv.fancy_name", label: "Veículo" },
                { value: "ta.fancy_name", label: "Agência" },
                { value: "tc.fancy_name", label: "Cliente" },
                { value: "tp.sponsor_vehicle", label: "Representante" },
                { value: "tg.name", label: "Grupo de Mídia" },
                { value: "tp.fk_id_responsable", label: "Executivo" },
                { value: "tp.number", label: "Número PI" },
              ],
            },
            {
              col: 6,
              type: "multiselect",
              label: "Campos de Valores",
              name: "fields_values",
              visible: graph?.type == 4,
              options: [
                { value: "net_value_approved", label: "Valor Líquido Faturado" },
                { value: "approved_gross_value", label: "Valor Bruto Faturado" },
                { value: "net_value_proposal", label: "Valor Líquido Aprovado" },
                { value: "gross_value_proposal", label: "Valor Bruto Aprovado" },
                { value: "received_value", label: "Valor Recebido" },
                { value: "comission_value", label: "Valor da Comissão" },
              ],
            },
            {
              col: 6,
              type: "multiselect",
              label: "Campos de Valores",
              name: "fields_values_proposals",
              visible: graph?.type == 7 && graph?.type_leads == 2,
              options: [
                { value: "gross_value_negotiated", label: "Valor Bruto Negociado" },
                { value: "net_value_negotiated", label: "Valor Líquido Negociado" },
                { value: "gross_value_predicted", label: "Valor Bruto Previsto" },
                { value: "net_value_predicted", label: "Valor Líquido Previsto" },
                { value: "gross_value_approved", label: "Valor Bruto Aprovado" },
                { value: "net_value_approved", label: "Valor Líquido Aprovado" },
              ],
            },
            {
              col: 6,
              type: "multiselect",
              label: "Campos de Valores",
              name: "fields_values_leads",
              visible: graph?.type == 7 && graph?.type_leads == 1,
              options: [
                { value: "value", label: "Valor" },
              ],
            },
            {
              col: 4,
              name: "groupby",
              label: "Agrupar Por",
              visible: graph?.type == 3 && graph?.type_graph !== 1,
              type: "select",
              options: [
                { value: "dt_start", label: "Data de Início" },
                { value: "dt_emission", label: "Data de Ganho" },
                { value: "tv.fancy_name", label: "Veículo" },
                { value: "ta.fancy_name", label: "Agência" },
                { value: "tc.fancy_name", label: "Cliente" },
                { value: "tg.name", label: "Grupo" },
                { value: "tp.fk_id_responsable", label: "Executivo" },
              ],
            },
            {
              col: 3,
              label: "Campo de Ordenação",
              name: "order_by",
              visible: (graph?.type == 3 || graph?.type == 4) && graph?.type_graph !== 1,
              type: "select",
              options: [
                {
                  value: "net_value_approved",
                  label: "Valor Líquido Faturado",
                  invisible:
                    !(graph?.metrics || []).includes("net_value_approved") && !(graph?.fields_values || []).includes("net_value_approved"),
                },
                {
                  value: "dt_emission",
                  label: "Data de Ganho"
                },
                {
                  value: "approved_gross_value",
                  label: "Valor Bruto Faturado",
                  invisible:
                    !(graph?.metrics || []).includes("approved_gross_value") &&
                    !(graph?.fields_values || []).includes("approved_gross_value"),
                },
                {
                  value: "net_value_proposal",
                  label: "Valor Líquido Aprovado",
                  invisible:
                    !(graph?.metrics || []).includes("net_value_proposal") && !(graph?.fields_values || []).includes("net_value_proposal"),
                },
                {
                  value: "gross_value_proposal",
                  label: "Valor Bruto Aprovado",
                  invisible:
                    !(graph?.metrics || []).includes("gross_value_proposal") &&
                    !(graph?.fields_values || []).includes("gross_value_proposal"),
                },
                {
                  value: "received_value",
                  label: "Valor Recebido",
                  invisible: !(graph?.metrics || []).includes("received_value") && !(graph?.fields_values || []).includes("received_value"),
                },
                {
                  value: "comission_value",
                  label: "Valor da Comissão",
                  invisible:
                    !(graph?.metrics || []).includes("comission_value") && !(graph?.fields_values || []).includes("comission_value"),
                },
              ],
            },
            {
              col: graph?.type == 4 ? 6 : 2,
              label: "Ordem",
              name: "sort",
              visible: [3, 4].includes(graph?.type) && graph?.type_graph !== 1,
              type: "select",
              options: [
                { value: "desc", label: "Decrescente" },
                { value: "asc", label: "Crescente" },
              ],
            },

            {
              col: graph?.type == 4 ? 6 : 3,
              label: "Limite",
              name: "limit",
              type: "select",
              options: [
                { value: 10, label: "10" },
                { value: 20, label: "20" },
                { value: 50, label: "50" },
                { value: 100, label: "100" },
                { value: -1, label: "Todos" },
              ],
              visible: [3, 4, 6, 7].includes(graph?.type) && graph?.type_graph !== 1,
            },

            {
              col: 12,
              type: "content",
              visible: [3, 4, 5].includes(graph?.type),
              content: (
                <div>
                  <h5>FILTROS</h5>
                  <Divider style={{ marginTop: 20 }} />
                </div>
              ),
            },
            {
              col: 12,
              name: "tags",
              type: "multiselect",
              label: "Etiquetas",
              options: tags.map((k) => ({ value: k.id_tags, label: k.name, color: k.color })),
              visible: [3, 4, 5, 6, 7, 8].includes(graph?.type) && graph?.type_leads !== 2,
            },
            {
              col: 12,
              type: "multiselect",
              name: "fk_id_status",
              label: "Status",
              options: [
                { value: 'in_progress', label: 'Em Andamento' },
                { value: 'finished', label: 'Finalizado' },
                { value: 'archived', label: 'Arquivados' },
                { value: 'not_realized', label: 'Não Realizados' },
              ],
              visible: [6, 7, 8].includes(graph?.type) && graph.type_leads !== 2,
            },
            {
              col: 12,
              type: "select",
              name: "fk_id_status_proposals",
              label: "Status",
              options: [
                { value: 'win', label: 'Ganho' },
                { value: 'loss', label: 'Perda' },
                { value: 'in_progress', label: 'Em Andamento' },
              ],
              visible: [6, 7, 8].includes(graph?.type) && graph.type_leads === 2,
            },
            {
              col: 12,
              type: "multiselect",
              name: "fk_id_status",
              label: "Status",
              options: status
                .filter((s) => s.sector == "COMERCIAL")
                .map((st) => {
                  return {
                    value: st.id_status,
                    label: st.name,
                    color: "black",
                  };
                }),
              visible: [3, 4, 5].includes(graph?.type),
            },
            {
              col: 12,
              type: "multiselect",
              name: "fk_id_vehicle",
              label: "Veículo",
              options: vehicles.map((v) => {
                return {
                  value: v.id_vehicle,
                  label: v.fancy_name,
                };
              }),
              visible: [3, 4, 5].includes(graph?.type),
            },
            {
              col: 12,
              type: "multiselect",
              name: "fk_id_group",
              label: "Grupo De Mídia",
              options: groups.map((group) => ({ value: group.id_groups, label: group.name })),
              visible: [3, 4, 5].includes(graph?.type),
            },
            {
              col: 12,
              type: "multiselect",
              name: "fk_id_client",
              label: "Cliente",
              options: clients.map((c) => {
                return {
                  value: c.id_client,
                  label: c.fancy_name,
                };
              }),
              visible: [3, 4, 5, 6, 7, 8].includes(graph?.type) && graph?.type_leads !== 2,
            },
            {
              col: 12,
              type: "text",
              name: "sponsor_vehicle",
              label: "Representante",
              visible: [3, 4, 5].includes(graph?.type),
            },
            {
              col: 12,
              type: "multiselect",
              name: "fk_id_agency",
              label: "Agência",
              options: agencies.map((ag) => {
                return {
                  value: ag.id_agency,
                  label: ag.fancy_name,
                };
              }),
              visible: [3, 4, 5, 6, 7, 8].includes(graph?.type),
            },
            {
              col: 12,
              type: "multiselect",
              name: "fk_id_responsable",
              label: "Executivo",
              options: users
                .filter((u) => u.fk_id_role == "executive")
                .map((v) => {
                  return {
                    value: v.id_user,
                    label: v.name,
                  };
                }),
              visible: [3, 4, 5].includes(graph?.type) || ([6, 7, 8].includes(graph?.type) && graph?.type_leads == 2),
            },
            {
              col: 12,
              type: "multiselect",
              name: "fk_id_square",
              label: "Praça de venda",
              options: squares.map((sq) => {
                return {
                  value: sq.id_square,
                  label: sq.uf,
                };
              }),
              visible: [3, 4, 5, 6, 7, 8].includes(graph?.type) && graph?.type_leads !== 2,
            },
            {
              col: 12,
              type: "multiselect",
              label: "Justificativa",
              name: "fk_id_justify",
              options: justifies.filter((j) => j.type_justify == 1).map((justify) => ({ value: justify.id_justify, label: justify.name })),
              visible: [3, 4, 5].includes(graph?.type),
            },
          ]}
          buttonBellow={170}
          onChangeField={(f, v) => {
            let graph_ = graph;
            graph_[f.name] = v;
            setGraph({ ...graph_ });
          }}
          values={graph}
          onSubmit={createGraph}
        />
      </CommonDialog>

      {openDashSidebar == true && (
        <OwnDashSidebar
          open={openDashSidebar}
          dashes={dashes}
          dashesToAdd={dashesToAdd}
          getDashesToAdd={getDashesToAdd}
          groups={groupsDashes}
          getDashes={getDashes}
          selectedDash={selectedDash}
          idDashSelected={idDashSelected}
          setSelectedDash={setSelectedDash}
          users={users}
          teams={teams}
          loadDash={loadDash}
          setIdDashSelected={setIdDashSelected}
          onClose={() => {
            setOpenDashSidebar(false);
            // setSingleContact([])
          }}
        />
      )}

      <div className="Header">

        {idDashSelected > 0 && user.access?.access_owndash_create_graph ? (
          <button
            onClick={() => {
              setOpenCreateGraph(true);
              setSelectedDash({})
            }}
          >
            <FuseSvgIcon>heroicons-outline:plus-circle</FuseSvgIcon>
            ADICIONAR GRÁFICO
          </button>
        ) : null}

        {(!!user.access?.access_owndash_create_graph  && idDashSelected > 0 )? (
          <button
            onClick={() => setEditMode(!editMode)}
          >
            <FuseSvgIcon>{editMode ?  'heroicons-outline:plus-circle' :'heroicons-outline:pencil-alt'}</FuseSvgIcon>
            {editMode ? 'MODO LEITURA' : 'MODO EDIÇÃO'}
          </button>
        ) : null}

        {user.access?.access_owndash_read_dashes ? (
          <button
            onClick={() => {
              setGraph({});
              setOpenDashSidebar(true);
              //  setOpenCreateGraph(true)
            }}
          >
            <FuseSvgIcon>heroicons-outline:plus-circle</FuseSvgIcon>
            DASHBOARD
          </button>
        ) : null}
      </div>
      {idDashSelected > 0 ? (
        <div>
          <CommonForm
            values={{
              ...selectedDash,
              dt_start: selectedDash.dt_start ? moment(selectedDash.dt_start).format('YYYY-MM-DD') : '',
              dt_end: selectedDash.dt_end ? moment(selectedDash.dt_end).format('YYYY-MM-DD') : ''
            }}
            fields={[
              {
                col: 1.2,
                type: "date",
                name: "dt_start",
                label: "De",
                period: [selectedDash.dt_start, selectedDash.dt_end]
              },
              {
                col: 1.2,
                type: "date",
                name: "dt_end",
                label: "De",
                period: [selectedDash.dt_start, selectedDash.dt_end]

              },
              {
                col: 1.6,
                type: "multiselect",
                name: "fk_id_vehicle",
                label: "Veículo",
                options: vehicles.filter(v => v.active === 1).map((v) => {
                  return {
                    value: v.id_vehicle,
                    label: v.fancy_name,
                  };
                }),
              },
              {
                col: 1.6,
                type: "multiselect",
                name: "fk_id_group",
                label: "Grupo De Mídia",
                options: groups.map((group) => ({ value: group.id_groups, label: group.name })),
              },
              {
                col: 1.6,
                type: "multiselect",
                name: "fk_id_agency",
                label: "Agência",
                options: agencies.map((ag) => {
                  return {
                    value: ag.id_agency,
                    label: ag.fancy_name,
                  };
                }),
              },
              {
                col: 1.6,
                type: "multiselect",
                name: "fk_id_square",
                label: "Praça de venda",
                options: squares.map((sq) => {
                  return {
                    value: sq.id_square,
                    label: sq.uf,
                  };
                }),
              },
              {
                col: 1.6,
                type: "multiselect",
                name: "fk_id_client",
                label: "Cliente",
                options: clients.map((sq) => {
                  return {
                    value: sq.id_client,
                    label: sq.fancy_name,
                  };
                }),
              },
              {
                col: 1.6,
                type: "multiselect",
                name: "fk_id_responsable",
                label: "Executivos",
                options: users.map((sq) => {
                  return {
                    value: sq.id_user,
                    label: sq.name,
                  };
                }),
              },
            ]}
            onChangeField={(f, v) => {
              let dash = selectedDash;
              if ((f.name == 'dt_start' || f.name == 'dt_end') && selectedDash.dt_start && selectedDash.dt_end) {
                if (!moment(v).isBetween(moment(selectedDash.dt_start), moment(selectedDash.dt_end))) {
                  toast(`Data selecionada fora do período da dashboard`, {
                    type: toast.TYPE.ERROR,
                  });

                  return
                }
              }
              dash[f.name] = v
              setSelectedDash(dash);
              getDataGraph(dash.id_dash, dash);
            }}
          />
        </div>
      ) : null}
      <ResponsiveReactGridLayout
        rowHeight={30}
        cols={colsLayout}
        layouts={layout}
        onLayoutChange={onLayoutChange}
        className="layout"
        isDroppable={editMode}
        isDraggable={editMode}

        // style={{
        //   backgroundColor: 'yellow',
        //   width: getSizeDiv()
        // }}
        isResizable={editMode}
        margin={[10, 10]}
        onDrag={(layout, oldItem, newItem, placeholder, e, element) => handleDrag(layout, oldItem, newItem, placeholder, e, element)}
        onDragStop={onDragStop}
      >
        {layout.map((item, i) => (
          <div
            key={item.i}
            data-grid={item}
            style={{
              background: item.i == itemMoving.i && !isOverTrash ? "#dddddd" : itemMoving.i && isOverTrash ? "red" : "white",
              opacity: item.i == itemMoving.i && isOverTrash ? 0.9 : 1,
            }}
            onMouseEnter={() => setHoveredDiv(item.i)}
            onMouseLeave={() => setHoveredDiv(null)}
          >
            {(hoveredDiv == item.i && user.access?.access_owndash_create_graph) ? (
              <div className="buttonsGrid fade-in">
                <IconButton color="primary" style={{ borderRadius: 0 }} onClick={() => onEdit(item)}>
                  <FuseSvgIcon size={14}>heroicons-outline:pencil-alt</FuseSvgIcon>
                </IconButton>
                <IconButton color="primary" style={{ borderRadius: 0 }} onClick={() => onRemove(item)}>
                  <FuseSvgIcon size={14}>heroicons-outline:trash</FuseSvgIcon>
                </IconButton>
              </div>
            ) : null}
            {dashItensToRender[i]?.type == 1 ? (
              <div
                style={{
                  width: "100%",
                  height: "100%", // ou qualquer altura desejada
                  backgroundImage: `url(${dashItensToRender[i].image})`, // Substitua pelo caminho da sua imagem
                  backgroundSize: "cover", // Preenche a div com a imagem
                  backgroundPosition: "center", // Centraliza a imagem na div
                  backgroundRepeat: "no-repeat",
                }}
              />
            ) : dashItensToRender[i]?.type == 2 ? (
              <div dangerouslySetInnerHTML={{ __html: dashItensToRender[i].text }} />
            ) : [3, 6].includes(dashItensToRender[i]?.type) ? (
              allData.find((d) => d.id_dash_itens == item.i)?.isCard == true ? (
                <div style={{ display: "flex", alignItems: "center", flexDirection: "column", padding: 20 }}>
                  <h4>{allData.find((d) => d.id_dash_itens == item.i)?.name}</h4>
                  <Divider />
                  {allData.find((d) => d.id_dash_itens == item.i)?.returnvalue ? (
                    <p dangerouslySetInnerHTML={{ __html: getFormattedValue(allData.find((d) => d.id_dash_itens == item.i)?.returnvalue, allData.find((d) => d.id_dash_itens == item.i)?.value) }}></p>
                  ) : (
                    <h1>{parseFloat(allData.find((d) => d.id_dash_itens == item.i)?.value || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</h1>
                  )}
                </div>
              ) : allData.find((d) => d.id_dash_itens == item.i) !== undefined ? (
                <div>
                  <div className="buttonsGraph">
                    <IconButton
                      color="primary"
                      style={{ borderRadius: 0 }}
                      onClick={() => downloadTableGraph(allData.find((d) => d.id_dash_itens == item.i), 'graph', 'pdf')}
                    >
                      <PictureAsPdfIcon />
                    </IconButton>
                    <IconButton
                      color="primary"
                      style={{ borderRadius: 0 }}
                      onClick={() => downloadTableGraph(allData.find((d) => d.id_dash_itens == item.i), 'graph', 'xlsx')}
                    >
                      <FilePresentIcon />
                    </IconButton>
                  </div>
                  <Chart
                    options={allData.find((d) => d.id_dash_itens == item.i)?.options}
                    series={allData.find((d) => d.id_dash_itens == item.i)?.series}
                    type={"bar"}
                    height={item.h * 35}
                  />
                </div>
              ) : (
                <div></div>
              )
            ) : [5, 8].includes(dashItensToRender[i]?.type) ? (
              <div>
                <h4>{allData.find((d) => d.id_dash_itens == item.i)?.name}</h4>
                {allData.find((d) => d.id_dash_itens == item.i)?.returnvalue ? (
                  <p dangerouslySetInnerHTML={{ __html: getFormattedValue(allData.find((d) => d.id_dash_itens == item.i)?.returnvalue, allData.find((d) => d.id_dash_itens == item.i)?.value) }}></p>
                ) : (
                  <h1>{parseFloat(allData.find((d) => d.id_dash_itens == item.i)?.value || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</h1>
                )}
              </div>
            ) : (
              <div className="tableDash">

                {showList(allData, item) == true && (
                  <>
                    <div className="buttonsGraph">
                      <IconButton
                        color="primary"
                        style={{ borderRadius: 0 }}
                        onClick={() => downloadTableGraph(allData.find((d) => d.id_dash_itens == item.i), 'column', 'pdf')}
                      >
                        <PictureAsPdfIcon />
                      </IconButton>
                      <IconButton
                        color="primary"
                        style={{ borderRadius: 0 }}
                        onClick={() => downloadTableGraph(allData.find((d) => d.id_dash_itens == item.i), 'column', 'xlsx')}
                      >
                        <FilePresentIcon />
                      </IconButton>
                    </div>
                    <CommonTable
                      data={allData.find((d) => d.id_dash_itens == item.i).data}
                      columns={allData.find((d) => d.id_dash_itens == item.i).columns}
                      id={"list_own_dash_" + item.i}
                      noHeader={true}
                      miniVersion={true}
                      underHeader={allData.find((d) => d.id_dash_itens == item.i).underHeader}
                      noPagination={true}
                      noMaxHeight={true}
                    />
                  </>
                )}
              </div>
            )}
          </div>
        ))}
      </ResponsiveReactGridLayout>
      <TrashZone openTrashZone={openTrashZone} />
      {openDeleteDialog ? (
        <ConfirmDialog
          title="Deseja deletar esse gráfico?"
          cancel={() => {
            setOpenDeleteDialog(false);
            setIsOverTrash(false);
            setItemMoving({});
          }}
          confirm={removeGraph}
        />
      ) : null}
    </div>
  );
};

export default OwnDash;
